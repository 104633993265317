<template>
    <div id="sidebar_nav" class="" :class="mob_MenuOpen ? 'mob_menu_show' : ''">      
        <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
            <div class="menu_icon_bar">
                <div class="mob_menu_header">
                    <a href="javascript:void(0)" class="mob_menu_back" @click="closeMenuAll">Back</a>
                </div>
                <div class="menu_scroller">    
                  <div class="sidebarloader" v-if="sidebarLoader">
                      <sidebarLoader />  
                  </div>        
                  <ul v-else>
                            <template>
                                <li v-for="(mitem, index) in primaryItems" :key="index" class="menu-item admin level_1"                                
                                :class="{ 'active': activeNav == mitem.id, 'visible_li': mitem.status, 'disablelist': !mitem.status }"
                                    v-bind:title="mitem.status === 0 ? 'Coming Soon' : ''" :id="mitem.id">                                                                     
                                    <a href="javascript:void(0)"
                                        v-on:click="mobLink(mitem.url, mitem.id, mitem.status)" :data-id="mitem.id"
                                        :key="index"><i class="menu-icon" v-bind:class="mitem.icon"></i>
                                        <span class="full_label">{{ mitem.description }}</span>
                                        <span class="short_label text-capitalize" v-bind:class="mitem.class">{{ mitem.name }}</span>                                        
                                    </a>
                                </li>
                            </template>
                        </ul>                  
                </div>
            </div>       
            <div id="product_menus" class="product_menus membermenu sidebar sidebarscorll" v-bind:style="menuOpen ? '' : ''" :class="classCtrl"
                v-show="menuOpen" :key="menuOpen">                
                    <div id="mms_menu" class="menu_list">
                        <div class='menu_list_items menu_scroller'>
                                <div class="mob_menu_header">
                                    <a href="javascript:void(0)" class="mob_menu_back" @click="secondMenuClose">Back</a>
                                    <!-- <h3><i class="menu_header-icon" v-bind:class="'head_' + mob_label.icon"></i>{{
                                        mob_label.full_name }}
                                    </h3> -->
                                </div>                                     
                                <ul>
                                        <li v-for="(module, menuIndex) in  menuItems"  :key="menuIndex" v-bind:class="isRouteActive(module.child, module.href)">                                           
                                            <router-link v-if="!module.external" :to="routerOther(module.href)" class="vueroute">
                                                {{ module.title }}
                                            </router-link>
                                            <a  :href="module.href" target="_blank" v-else>                                           
                                            {{ module.title }}                                           
                                            </a>
                                            
                                            <!-- <pre> {{ module }}</pre> -->
                                            <ul v-if="module.child"
                                                >                                                
                                                <li v-for="( childPage, childIndex ) in  module.child "
                                                    :key="childIndex" v-bind:class="isRouteActive2(childPage.child, childPage.href)">                                                      
                                                        <a  :href="childPage.href" target="_blank" v-if="childPage.external">                                           
                                                        {{ childPage.title }}                                           
                                                        </a>  
                                                        <router-link :to="childPage.href" v-else>
                                                            {{ childPage.title }}
                                                        </router-link>
                                                          <ul v-if=" childPage.child " :class=" childPage.child ? 'subchild' :''">
                                                            <li v-for="( item, itemkey ) in childPage.child" :key="itemkey">                                                              
                                                                <router-link :to="item.href" >
                                                              {{ item.title }}
                                                            </router-link>
                                                              </li>
                                                          </ul>
                                                  </li>                                                  
                                            </ul>
                                        </li>                                     
                                </ul>                           
                        </div>
                    </div>                
            </div>            
        </aside>
    </div>
  <!-- <aside class="main-sidebar sidebar-dark-primary">
    <div class="sidebar-logo">
      <a href="#" class="brand-link" v-if="this.getSiteProps('member.logo') == undefined">
        <img
          :src="'/' + this.getSiteProps('login.logo')"
          v-if="'/' + this.getSiteProps('login.logo')"
          class="padding_top"
          style="opacity: 1;"
        />
        <span class="brand-text font-weight-bolder"></span>
      </a>
      <a href="#" class="brand-link" v-else>
        <img
          :src="'/' + this.getSiteProps('member.logo')"
          v-if="'/' + this.getSiteProps('member.logo')"
          class="padding_top"
          style="opacity: 1;"
        />
        <span class="brand-text font-weight-bolder"></span>
      </a>
    </div>

    <div class="sidebar">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex align-items-baseline">
        <div class="image">
          <img
            :src="getProfilePicImg()"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info">
          <a href="#" class="d-block text-capitalize">{{ (subdomain=='waterski'&&memberInfoData.reg_type_id === 7) ? memberInfoData.campski_name : memberInfoData.full_name }}</a>
        </div>
      </div>  




      <nav class="mt-2 pt-3 pb-3 ">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
          <li class="nav-item" v-for="(pm,k1) in menuItems" :key="k1">
            <template v-if="pm.href">
              <a v-if="pm.external" :href="pm.href" target="_blank" :class="['nav-link', pm.class || '']">
                <i :class="['nav-icon fas', pm.icon]" v-if="pm.icon"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
                <img v-if="pm.badge && pm.badge.element && pm.badge.element == 'img'" :src="pm.badge.attributes.src" :class="pm.badge.class">
              </a>
              <router-link :to="pm.href" :class="['nav-link', pm.class || '']" active-class="active" v-else>
                <i :class="['nav-icon fas', pm.icon]"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
              </router-link>
            </template>
            <template v-else>
              <a href="#" :class="['nav-link', pm.class || '']">
                <i :class="['nav-icon fas', pm.icon]"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
              </a>
            </template>
            <ul class="nav nav-treeview" v-if="pm.child">
              <li class="nav-item" v-for="(pmc,k2) in pm.child" :key="k2">
                <a 
                  v-if="pmc.external" 
                  :href="pmc.form_post == undefined || pmc.form_post == false ? pmc.href : 'javascript:void(0);'" 
                  :data-href="pmc.form_post != undefined && pmc.form_post == true ? pmc.href : ''" 
                  :target="pmc.form_post == undefined || pmc.form_post == false ? '_blank' : '_self'" 
                  @click="linkPost(pmc.href, pmc.form_post)"               
                  :class="['nav-link', pmc.class || '']">
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ pmc.title }}</p>
                </a>
                <router-link :to="pmc.href" :class="['nav-link', pmc.class || '']" active-class="active" v-else>
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ pmc.title }}</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside> -->
</template>

<style scoped>
/* .nav-sidebar a.nav-link.router-link-exact-active.router-link-active {
    font-weight: 700;
    color: #fff;
    border-left: 3px solid #FF0000;
    border-radius: 0;
} */
</style>

<script>
import $ from 'jquery';
import { SidebarMenu } from "vue-sidebar-menu";
import moment from "moment";
import menujson from '../../../../assets/menu.json';
import { EventBus } from '@/event-bus';
import VueCookies from 'vue-cookies';
import axios from "axios";
import sidebarLoader from '../../../admin/components/sidebarLoader.vue';

export default {
  name: "sidebar",
   components: { sidebarLoader },
  data() {
    return {
      memberInfoData: [], 
      role: 0,
      menuItems: [],          
      waterskifamilyMenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        }, 
         {                   
          href: "/member/family-profile",
          title: "Family Profile",
          icon: "far fa-address-card",          
        },       
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },
        {
          href: "/member/ncsi",
          title: "Background Screening",
          icon: "fas fa-table",
        },
        {
          href: "/member/mvr-review",
          title: "MVR Review",
          icon: "fas fa-star",
        },
        {
          href:"",
          title: "Collegiate Registration",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            { 
              href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp",
              title: "Team Captain Tools",
              external: true,
              icon: "far fa-circle ",
            },
            {
              href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List",
              title: "Individual Event Waivers",
              external: true,
              icon: "far fa-circle ",
            },
            {
              href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp",
              title: "Registrar Tools",
              external: true,
              icon: "far fa-circle",
            },
          ],
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs",
        }
      ],
      digitalMembermenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        }
      ],
      membermenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },    
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },
        {
          href: "/member/ncsi",
          title: "Background Screening",
          icon: "fas fa-table",
        },
        // {
        //   href: "/member/mvr-review",
        //   title: "Background Screening",
        //   icon: "fas fa-star",
        // },
         {
            href:"",
            title: "Collegiate Registration",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              { 
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp",
                title: "Team Captain Tools",
                external: true,
                icon: "far fa-circle ",
              },
              {
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List",
                title: "Individual Event Waivers",
                external: true,
                icon: "far fa-circle ",
              },
              {
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp",
                title: "Registrar Tools",
                external: true,
                icon: "far fa-circle",
              },
            ],
          },
          {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs", 
        }
      ],
      campskimembermenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/campskimyprofile",
          title: "Camp or School Details",
          icon: "far fa-address-card",
        },
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs",
        }
      ],
      membermenu1: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },    
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },        
        {
          href: "/member/ncsi",
          title: "Background Screening",
          icon: "fas fa-table",
        },
        {
          href: "/member/mvr-review",
          title: "MVR Review",
          icon: "fas fa-star",
        },
         {
            href:"",
            title: "Collegiate Registration",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              { 
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp",
                title: "Team Captain Tools",
                external: true,
                form_post: true,
                icon: "far fa-circle ",
              },
              {
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List",
                title: "Individual Event Waivers",
                external: true,
                form_post: true,
                icon: "far fa-circle ",
              },
              {
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp",
                title: "Registrar Tools",
                external: true,
                form_post: true,
                icon: "far fa-circle",
              },
            ],
          },
        /*{
          href: "https://staging-lms.usawaterski.org/my",
          external: true,
          title: "Courses",
          icon: "fas fa-clipboard",
        },*/
          {
          href: "/member/preferences",
         title: "Preferences",
          icon: "fas fa-cogs", 
        }
      ],
       shooting: {
      memberMenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },       
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },                          
        {
          href: process.env.VUE_APP_SHOOTING_EMS_URL + "event_list",
          title: "Events",
          external: true,
          icon: "fas fa-table",
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs",
        },
        {
          href: "/member/shooting-result",
          title: "Shooting Result",
          icon: "fas fa-trophy",
        },
        {
          href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
          external: true,
          title: "Safesport Training",
          icon: "fas fa-clipboard",
        },
        {
          href: "/member/ncsi",
          title: "Background Screening",
          icon: "fas fa-table",
        },
        {
          href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
          external: true,
          title: "Courses",
          icon: "fas fa-clipboard",
        },
        {
          href: "/member/volunteering",
          title: "Volunteering",
          icon: "fas fa-hands-helping",
          permission: "volunteering",
        },
        {
          href: "/member/travel-information",
          title: "Travel Information",
          icon: "fas fa-plane-departure",
          permission: "travel_form"
        }
      ],
      familyMenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },   
        {                   
          href: "/member/family-profile",
          title: "Family Profile",
          icon: "far fa-address-card",          
        },    
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },        
        {
          href: process.env.VUE_APP_SHOOTING_EMS_URL + "event_list",
          title: "Events",
          external: true,
          icon: "fas fa-table",
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs", 
        },
        {
          href: "/member/shooting-result",
          title: "Shooting Result",
          icon: "fas fa-trophy",
        },
          {
            href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
            external: true,
            title: "Safesport Training",
            icon: "fas fa-clipboard",
          },
        {
            href: "/member/ncsi",
            title: "Background Screening",
            icon: "fas fa-table", 
        },
        {
            href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
            external: true,
            title: "Courses",
            icon: "fas fa-clipboard",
        },
        {
          href: "/member/volunteering",
          title: "Volunteering",
          icon: "fas fa-hands-helping",
          permission: "volunteering",
        },
        {
          href: "/member/travel-information",
          title: "Travel Information",
          icon: "fas fa-plane-departure",
          permission: "travel_form"
        }
      ],
       fanClubMenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },       
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        }, 
        {
          href: process.env.VUE_APP_SHOOTING_EMS_URL + "event_list",
          title: "Events",
          external: true,
          icon: "fas fa-table",
        },
        {
          href: "/member/shooting-result",
          title: "Shooting Result",
          icon: "fas fa-trophy",
        },
        {
          href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
          external: true,
          title: "Safesport Training",
          icon: "fas fa-clipboard",
        },
        {
            href: "/member/ncsi",
            title: "Background Screening",
            icon: "fas fa-table", 
        },
        {
            href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
            external: true,
            title: "Courses",
            icon: "fas fa-clipboard",
        },
        {
          href: "/member/volunteering",
          title: "Volunteering",
          icon: "fas fa-hands-helping",
          permission: "volunteering",
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs",
          },
        {
          href: "/member/travel-information",
          title: "Travel Information",
          icon: "fas fa-plane-departure",
          permission: "travel_form"
        }
      ],
      fanMenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },
        {
          href: process.env.VUE_APP_SHOOTING_EMS_URL + "event_list",
          title: "Events",
          external: true,
          icon: "fas fa-table",
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs",
        }
      ]
       },
       alliancefastpitch: {
        memberMenu: [
          {
            href: "/member/dashboard",
            title: "Dashboard",
            icon: "fas fa-tachometer-alt",
          },
          {
            href: "/member/myprofile",
            title: "My Profile",
            icon: "far fa-address-card",
          },       
          {
            href: "/member/membership-history",
            title: "Membership History",
            icon: "far fa-address-book",
          }
        ]
       },
      partnersStatus:false,
      menuOpen: true,
      mob_MenuOpen: false,
      sec_menuClass: false,
      mob_label: {},      
      menuItems1 : menujson['iconbar'],
      sidebarLoader: true,
      activeNav: "1",
    };
  },
    /* computed for new menu design */
    computed: {     
      classCtrl() {               
          if (this.mob_MenuOpen) {
              $('body').addClass('oveflowhidden');

              $('#product_menus').animate({ "left": "0px" }, "slow");

              return {
                  'menu_items_show mob_items_visible': this.menuOpen,
              };
          } else {
              $('body').removeClass('oveflowhidden');

              return {
                  'menu_items_show': this.menuOpen,
              }
          }
      }
  },
  /* created for new menu design*/
  created() {   
      EventBus.$on('showClubmenuevent', (evt) => {      
         this.menuOpen = evt;    
      });
      EventBus.$on('showclubmenuMob', (evt) => {   
         this.mob_MenuOpen = evt;
      });
    },
  methods: {
    getPrimaryMenu(){      
      axios.get(this.basePath + "api/mms/get-module-menu-config", { headers:this.memberHeaders})
                .then((response) => {
                    this.primaryItems = response.data.data;                  
                    this.sidebarLoader = false;
                })
                .catch(error => {
                    console.log("error")
                })              
    },
    getProfilePicImg() {
      return (
        (this.memberInfoData.profile_pic != null
          ? process.env.VUE_APP_URL + this.memberInfoData.profile_pic
          : "/dist/img/avatarimg.png") +
        "?v=" +
        moment().format("x")
      );
    },
    linkPost(link, ispost){
      if(ispost != undefined && ispost == true){
        var form = document.createElement("form");
        form.method = "POST";
        form.action = link;
        form.target = "_blank";
        var id_element = document.createElement("input");
        id_element.type = "hidden";
        id_element.value = localStorage.id;
        id_element.name = "id";
        form.appendChild(id_element);
        var auth_element = document.createElement("input");
        auth_element.type = "hidden";
        auth_element.value = localStorage.auth;
        auth_element.name = "auth";
        form.appendChild(auth_element);

        document.body.appendChild(form);

        form.submit(); 
      }
    },
    partnerStatus(){
      axios.get(this.basePath+'api/partners/getPartnerStatus',{ headers:this.memberHeaders })
          .then(function (response) {
            if (response.data.status == 'success') {
              this.partnersStatus = response.data.data;
            }
             else
              console.log(response);
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    partnerMenu(){
      let memberMenu = {
        href: "/member/partners",
        title: "Partners",
        icon: "fas fa-handshake",
        permission: "partners"
      };
      console.log(this.menuItems);
      this.menuItems.push(memberMenu);
      console.log(this.menuItems);
    },
    mobLink(link,id, status){
        if (id == 1){         
          this.activeMenu(id);
        }
        else if(status === 0){
          window.location.href = 'javascript:void(0)'
        }
        else if(id === 3){
         window.open(link, '_blank');
        }
        else{
          window.location.href = link;
        } 
    },
        isRouteActive(child, route) {            
            let cl = '';
            let currentRoute = this.$route.path;
            if (child) {
                const exists = child.some(childit => childit.href === currentRoute);
                if (exists) {
                    cl += 'has-sub opened active';
                }
                else {
                    cl += 'has-sub';
                }
            }
            if (this.$route.path === route) {
                cl += 'opened active';
            }
            return cl;
        },
        isRouteActive2(child, route) {
            let cl = '';
            let currentRoute = this.$route.path;
            if (child) {
                const exists = child.some(childit => childit.href === currentRoute);
                if (exists) {
                    cl += 'has-sub opened active';
                }
                else {
                    cl += 'has-sub';
                }
            }
            if (this.$route.path === route) {
                cl += 'active';
            }
            return cl;
        },
        activeMenu(id) {
            this.activeNav = id;
            this.mobActiveText()
            if (this.mob_MenuOpen) {
                this.sec_menuClass = true;
            } else {
                this.sec_menuClass = false;
            }
        },
        closeMenuAll() {            
            this.mob_MenuOpen = false;
            this.sec_menuClass = false;            
            this.secondMenuClose();
            EventBus.$emit('mobTogglerClose', false)
            $('body').removeClass('oveflowhidden');
        },
        secondMenuClose() {           
            $('.menu_icon_bar').removeClass('showMenuList');
            $('#product_menus .menu_list').removeClass('showNav');
            $('.menu_list').removeClass('opened active');
        },
        mobActiveText() {           
            let labeltext = this.activeNav
            let settingsIndex = this.menuItems.findIndex(function (element) {
                return element.id === labeltext;
            });           
            this.mob_label = this.menuItems[settingsIndex];
        },
        routerOther(url) {
            return url;
        },
        maintoSubToggle() {
            this.menuOpen = true;
            EventBus.$emit('deskTogglerOpen', true);
        },
  },
  mounted() {
    this.getPrimaryMenu();
    this.memberInfoData = JSON.parse(localStorage.getItem("member"));
    this.role = JSON.parse(localStorage.getItem("role")); 

    EventBus.$on('changeProfileName', name => {
      if(this.memberInfoData.reg_type_id == 7)
        this.memberInfoData.campski_name = name;
      else
        this.memberInfoData.full_name = name;
    });
    EventBus.$on('getGroupCommittee', name => {
      this.groupCommittee = name;
    });
    let sso_cookie = {
      name : 'id',
      token: this.memberInfoData.person_id,
      domain: '.usawaterski.org'
    };
    let d = new Date();
    d.setTime(d.getTime() + (30 * 60 * 1000));
    let expires = "expires=" + d;
    this.$cookies.set(sso_cookie.name, sso_cookie.token, expires, "/", sso_cookie.domain, true,"None");
    this.$cookies.set("auth", true, expires, "/", sso_cookie.domain, true,"None");
    //document.cookie = sso_cookie.name+"=" + sso_cookie.token + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
    //document.cookie = "auth=" + true + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
    console.log(sso_cookie.name+"=" + sso_cookie.token + ";" + expires + ";domain="+sso_cookie.domain+";path=/");
    console.log("auth=" + true + ";" + expires + ";domain="+sso_cookie.domain+";path=/");
    console.log('id');
    this.$cookies.get('id');
    console.log('auth');
    this.$cookies.get('auth');
    console.log('---------------');
    document.cookie = "_id=" + sso_cookie.token + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
    document.cookie = "_auth=" + true + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
    console.log(document.cookie);
    console.log('=============');
    localStorage.setItem("id", sso_cookie.token);
    localStorage.setItem("auth", true);

    if(this.subdomain == "waterski" && this.memberInfoData.reg_type_id == 3) this.menuItems = this.waterskifamilyMenu;
    else if(this.subdomain == "waterski" && this.memberInfoData.reg_type_id == 7) this.menuItems = this.campskimembermenu;
    else if(this.subdomain == "waterski" && this.memberInfoData.reg_membership_id == 13 || this.memberInfoData.reg_type_id == 5)this.menuItems = this.digitalMembermenu;    
    else if(this.subdomain == "waterski"  && this.groupCommittee.screening == true) this.menuItems = this.membermenu1;
    else if(this.subdomain == "waterski") this.menuItems = this.membermenu;  

    if(this.subdomain == "shooting" && this.memberInfoData.reg_type_id == 3) this.menuItems = this.shooting.familyMenu;
    else if(this.subdomain == "shooting" && this.memberInfoData.reg_type_id == 6) this.menuItems = this.shooting.fanClubMenu;
    else if(this.subdomain == "shooting" && this.memberInfoData.reg_type_id == 7) this.menuItems = this.shooting.fanMenu;
    else if(this.subdomain == "shooting") this.menuItems = this.shooting.memberMenu; 
    else if(this.subdomain == "alliancefastpitch") this.menuItems = this.alliancefastpitch.memberMenu;  
    

    
    this.menuItems = this.menuItems.filter((menu,i) => {
        if(!((menu.permission=="travel_form" && this.memberInfoData.travel_form==false)) )
        if(!((menu.permission=="volunteering" && this.memberInfoData.volunteering==false)) )
        return menu;
     });
    this.partnerStatus();
    window.sidebar = this;
    this.mobActiveText() 
  },
  watch :{
    partnersStatus:function (val){
      this.partnersStatus = val;
      if(this.partnersStatus==true)
        this.partnerMenu();
    },
    $route(to, from) {      
            if (to.path !== '#' && to.path !== from.path) {
                if (this.mob_MenuOpen) {
                    this.mob_MenuOpen = false;
                    EventBus.$emit('mobTogglerClose', false)
                    $('body').removeClass('oveflowhidden');
                }
            } else {
                /* Dropdown menu clicking */
            }
        }
  }
};

$(document).on('click', '.menu_icon_bar .menu-item.member.visible_li > a', function () {   
  let menu_id = $(this).data('id');
    if (menu_id != 1){              
          if (menu_id === 2) {            
              return false;     
              }else if(menu_id === 3){            
              return false;          
        }
    }else{    
        if (menu_id === 1) {
        window.sidebar.maintoSubToggle();
        $('.menu_icon_bar').addClass('showMenuList');
        $('#mms_menu').addClass('showmms'); 
        $('.menu_icon_bar .menu-item').removeClass('active');
        $(this).parent('li').addClass('active');
        $('#product_menus').hide();
        $('#product_menus .menu_list').removeClass('showNav');
        $('#product_menus #' + menu_id + '_menu').addClass('showNav');
        $('#product_menus').show();
        $('.has-sub').removeClass('opened active');
        }
    }
});

$(document).on('click', '.membermenu li.has-sub>a', function () {
    console.log(" " , element.hasClass('opened'));
    $(this).removeAttr('href');
    let element = $(this).parent('li');
    if (element.hasClass('opened')) {
        element.removeClass('opened active');
        element.find('li').removeClass('opened active');
        element.find('ul').slideUp();
    }
    else {        
        element.addClass('opened active');
        element.children('ul').slideDown();
        element.siblings('li').children('ul').slideUp();
        element.siblings('li').removeClass('opened active');
        element.siblings('li').find('li').removeClass('opened active');
        element.siblings('li').find('ul').slideUp();
    }
});
</script>
<style >
.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #b33132 !important;
    color: #fff;
}
 .user-panel .info{
       white-space: initial !important;
 }
</style>