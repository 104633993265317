//Ref: https://www.itsolutionstuff.com/post/how-to-declare-global-variable-in-vue-jsexample.html
import Vue from "vue";
import moment from "moment";
import momentTimezone from 'moment-timezone';
import axios from "axios";
import * as $ from 'jquery';

Vue.mixin({
    data() {
        return {
            http: axios,
            basePath: process.env.VUE_APP_URL,
            stripeKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
            adminHeaders: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('admintoken')
            },
            clubHeaders: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('clubToken')
            },
            memberHeaders: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('membertoken')
            },
            loggedMemberInfo: JSON.parse(localStorage.getItem('member')),
            loggedClubInfo: JSON.parse(localStorage.getItem('team')),
            paymentStatus: {
                "0": "-NA-",
                "1": "INITIATED",
                "2": "Payment Initiated",
                "3": "Payment Failed",
                "4": "PAID",
                "5": "Error",
                "6": "Refund"
            },
            listOfStates: [
                { state_code: 'AL', state: 'Alabama' },
                { state_code: 'AK', state: 'Alaska' },
                { state_code: 'AZ', state: 'Arizona' },
                { state_code: 'AR', state: 'Arkansas' },
                { state_code: 'CA', state: 'California' },
                { state_code: 'CO', state: 'Colorado' },
                { state_code: 'CT', state: 'Connecticut' },
                { state_code: 'DE', state: 'Delaware' },
                { state_code: 'DC', state: 'District Of Columbia' },
                { state_code: 'FL', state: 'Florida' },
                { state_code: 'GA', state: 'Georgia' },
                { state_code: 'HI', state: 'Hawaii' },
                { state_code: 'ID', state: 'Idaho' },
                { state_code: 'IL', state: 'Illinois' },
                { state_code: 'IN', state: 'Indiana' },
                { state_code: 'IA', state: 'Iowa' },
                { state_code: 'KS', state: 'Kansas' },
                { state_code: 'KY', state: 'Kentucky' },
                { state_code: 'LA', state: 'Louisiana' },
                { state_code: 'ME', state: 'Maine' },
                { state_code: 'MD', state: 'Maryland' },
                { state_code: 'MA', state: 'Massachusetts' },
                { state_code: 'MI', state: 'Michigan' },
                { state_code: 'MN', state: 'Minnesota' },
                { state_code: 'MS', state: 'Mississippi' },
                { state_code: 'MO', state: 'Missouri' },
                { state_code: 'MT', state: 'Montana' },
                { state_code: 'NE', state: 'Nebraska' },
                { state_code: 'NV', state: 'Nevada' },
                { state_code: 'NH', state: 'New Hampshire' },
                { state_code: 'NJ', state: 'New Jersey' },
                { state_code: 'NM', state: 'New Mexico' },
                { state_code: 'NY', state: 'New York' },
                { state_code: 'NC', state: 'North Carolina' },
                { state_code: 'ND', state: 'North Dakota' },
                { state_code: 'OH', state: 'Ohio' },
                { state_code: 'OK', state: 'Oklahoma' },
                { state_code: 'OR', state: 'Oregon' },
                { state_code: 'PA', state: 'Pennsylvania' },
                { state_code: 'PR', state: 'Puerto Rico' },
                { state_code: 'RI', state: 'Rhode Island' },
                { state_code: 'SC', state: 'South Carolina' },
                { state_code: 'SD', state: 'South Dakota' },
                { state_code: 'TN', state: 'Tennessee' },
                { state_code: 'TX', state: 'Texas' },
                { state_code: 'UT', state: 'Utah' },
                { state_code: 'VT', state: 'Vermont' },
                { state_code: 'VA', state: 'Virginia' },
                { state_code: 'WA', state: 'Washington' },
                { state_code: 'WV', state: 'West Virginia' },
                { state_code: 'WI', state: 'Wisconsin' },
                { state_code: 'WY', state: 'Wyoming' },
            ],
            listOfCountries: [
                { country: 'USA', code: 'US' },
                { country: 'Afghanistan', code: 'AF' },
                { country: 'Åland Islands', code: 'AX' },
                { country: 'Albania', code: 'AL' },
                { country: 'Algeria', code: 'DZ' },
                { country: 'American Samoa', code: 'AS' },
                { country: 'Andorra', code: 'AD' },
                { country: 'Angola', code: 'AO' },
                { country: 'Anguilla', code: 'AI' },
                { country: 'Antarctica', code: 'AQ' },
                { country: 'Antigua and Barbuda', code: 'AG' },
                { country: 'Argentina', code: 'AR' },
                { country: 'Armenia', code: 'AM' },
                { country: 'Aruba', code: 'AW' },
                { country: 'Australia', code: 'AU' },
                { country: 'Austria', code: 'AT' },
                { country: 'Azerbaijan', code: 'AZ' },
                { country: 'Bahamas', code: 'BS' },
                { country: 'Bahrain', code: 'BH' },
                { country: 'Bangladesh', code: 'BD' },
                { country: 'Barbados', code: 'BB' },
                { country: 'Belarus', code: 'BY' },
                { country: 'Belgium', code: 'BE' },
                { country: 'Belize', code: 'BZ' },
                { country: 'Benin', code: 'BJ' },
                { country: 'Bermuda', code: 'BM' },
                { country: 'Bhutan', code: 'BT' },
                { country: 'Bolivia', code: 'BO' },
                { country: 'Botswana', code: 'BW' },
                { country: 'Brazil', code: 'BR' },
                { country: 'Britain', code: 'GB' },
                { country: 'British Virgin Islands', code: 'VG' },
                { country: 'Brunei Darussalam', code: 'BN' },
                { country: 'Bulgaria', code: 'BG' },
                { country: 'Burma', code: 'MM' },
                { country: 'Burundi', code: 'BI' },
                { country: 'Cambodia', code: 'KH' },
                { country: 'Cameroon', code: 'CM' },
                { country: 'Canada', code: 'CA' },
                { country: 'Cayman Islands', code: 'KY' },
                { country: 'Central African Rep.', code: 'CF' },
                { country: 'Chad', code: 'TD' },
                { country: 'Chile', code: 'CL' },
                { country: 'China', code: 'CN' },
                { country: 'Colombia', code: 'CO' },
                { country: 'Congo', code: 'CG' },
                { country: 'Costa Rica', code: 'CR' },
                { country: 'Cuba', code: 'CU' },
                { country: 'Cyprus', code: 'CY' },
                { country: 'Czech Republic', code: 'CZ' },
                { country: 'Denmark', code: 'DK' },
                { country: 'Dominican Republic', code: 'DO' },
                { country: 'Ecuador', code: 'EC' },
                { country: 'Egypt', code: 'EG' },
                { country: 'El Salvador', code: 'SV' },
                { country: 'England', code: 'GB' },
                { country: 'Equatorial Guinea', code: 'GQ' },
                { country: 'Estonia', code: 'EE' },
                { country: 'Ethiopia', code: 'ET' },
                { country: 'Falkland Islands', code: 'FK' },
                { country: 'Faroe Islands', code: 'FO' },
                { country: 'Finland', code: 'FI' },
                { country: 'France', code: 'FR' },
                { country: 'Gabon', code: 'GA' },
                { country: 'Gambia', code: 'GM' },
                { country: 'Georgia', code: 'GE' },
                { country: 'Germany', code: 'DE' },
                { country: 'Ghana', code: 'GH' },
                { country: 'Gibraltar', code: 'GI' },
                { country: 'Great Britain', code: 'GB' },
                { country: 'Greece', code: 'GR' },
                { country: 'Greenland', code: 'GL' },
                { country: 'Grenada', code: 'GD' },
                { country: 'Guadeloupe', code: 'GP' },
                { country: 'Guam', code: 'GU' },
                { country: 'Guatemala', code: 'GT' },
                { country: 'Guinea', code: 'GN' },
                { country: 'Guyana', code: 'GY' },
                { country: 'Haiti', code: 'HT' },
                { country: 'Honduras', code: 'HN' },
                { country: 'Hong Kong', code: 'HK' },
                { country: 'Hungary', code: 'HU' },
                { country: 'Iceland', code: 'IS' },
                { country: 'India', code: 'IN' },
                { country: 'Indonesia', code: 'ID' },
                { country: 'Iran', code: 'IR' },
                { country: 'Iraq', code: 'IQ' },
                { country: 'Ireland', code: 'IE' },
                { country: 'Israel', code: 'IL' },
                { country: 'Italy', code: 'IT' },
                { country: 'Ivory Coast', code: 'CI' },
                { country: 'Jamaica', code: 'JM' },
                { country: 'Japan', code: 'JP' },
                { country: 'Jordan', code: 'JO' },
                { country: 'Kazakhstan', code: 'KZ' },
                { country: 'Kenya', code: 'KE' },
                { country: 'Korea', code: 'KR' },
                { country: 'Kuwait', code: 'KW' },
                { country: 'Kyrgyzstan', code: 'KG' },
                { country: 'Laos', code: 'LA' },
                { country: 'Latvia', code: 'LV' },
                { country: 'Lebanon', code: 'LB' },
                { country: 'Lesotho', code: 'LS' },
                { country: 'Liberia', code: 'LR' },
                { country: 'Libya', code: 'LY' },
                { country: 'Liechtenstein', code: 'LI' },
                { country: 'Lithuania', code: 'LT' },
                { country: 'Luxembourg', code: 'LU' },
                { country: 'Macau', code: 'MO' },
                { country: 'Madagascar', code: 'MG' },
                { country: 'Malaysia', code: 'MY' },
                { country: 'Mali', code: 'ML' },
                { country: 'Malta', code: 'MT' },
                { country: 'Mauritania', code: 'MR' },
                { country: 'Mauritius', code: 'MU' },
                { country: 'Mexico', code: 'MX' },
                { country: 'Moldova', code: 'MD' },
                { country: 'Monaco', code: 'MC' },
                { country: 'Morocco', code: 'MA' },
                { country: 'Mozambique', code: 'MZ' },
                { country: 'Namibia', code: 'NA' },
                { country: 'Nepal', code: 'NP' },
                { country: 'Netherlands', code: 'NL' },
                { country: 'Netherlands Antilles', code: 'AN' },
                { country: 'New Zealand', code: 'NZ' },
                { country: 'Nicaragua', code: 'NI' },
                { country: 'Niger', code: 'NE' },
                { country: 'Nigeria', code: 'NG' },
                { country: 'North Korea', code: 'KP' },
                { country: 'Northern Ireland', code: 'GB' },
                { country: 'Norway', code: 'NO' },
                { country: 'Oman', code: 'OM' },
                { country: 'Pakistan', code: 'PK' },
                { country: 'Palau', code: 'PW' },
                { country: 'Panama', code: 'PA' },
                { country: 'Papua New Guinea', code: 'PG' },
                { country: 'Paraguay', code: 'PY' },
                { country: 'Peru', code: 'PE' },
                { country: 'Philippines', code: 'PH' },
                { country: 'Poland', code: 'PL' },
                { country: 'Portugal', code: 'PT' },
                { country: 'Qatar', code: 'QA' },
                { country: 'Romania', code: 'RO' },
                { country: 'Russia', code: 'RU' },
                { country: 'Rwanda', code: 'RW' },
                { country: 'Saint Helena', code: 'SH' },
                { country: 'Saint Vincent', code: 'VC' },
                { country: 'San Marino', code: 'SM' },
                { country: 'Saudi Arabia', code: 'SA' },
                { country: 'Senegal', code: 'SN' },
                { country: 'Serbia', code: 'RS' },
                { country: 'Sierra Leone', code: 'SL' },
                { country: 'Singapore', code: 'SG' },
                { country: 'Slovakia', code: 'SK' },
                { country: 'Somalia', code: 'SO' },
                { country: 'South Africa', code: 'ZA' },
                { country: 'South Korea', code: 'KR' },
                { country: 'Spain', code: 'ES' },
                { country: 'Sri Lanka', code: 'LK' },
                { country: 'Sudan', code: 'SD' },
                { country: 'Suriname', code: 'SR' },
                { country: 'Swaziland', code: 'SZ' },
                { country: 'Sweden', code: 'SE' },
                { country: 'Switzerland', code: 'CH' },
                { country: 'Syria', code: 'SY' },
                { country: 'Tahiti', code: 'PF' },
                { country: 'Taiwan', code: 'TW' },
                { country: 'Tajikistan', code: 'TJ' },
                { country: 'Tanzania', code: 'TZ' },
                { country: 'Thailand', code: 'TH' },
                { country: 'Togo', code: 'TG' },
                { country: 'Trinidad West Indies', code: 'TT' },
                { country: 'Tunisia', code: 'TN' },
                { country: 'Turkey', code: 'TR' },
                { country: 'Turkmenistan', code: 'TM' },
                { country: 'Uganda', code: 'UG' },
                { country: 'Ukraine', code: 'UA' },
                { country: 'United Arab Emirates', code: 'AE' },
                { country: 'United Kingdom', code: 'GB' },
                { country: 'Uruguay', code: 'UY' },
                { country: 'Uzbekistan', code: 'UZ' },
                { country: 'Venezuela', code: 'VE' },
                { country: 'Vietnam', code: 'VN' },
                { country: 'Virgin Islands', code: 'VG' },
                { country: 'Wales', code: 'GB' }, // Part of the United Kingdom
                { country: 'Yemen', code: 'YE' },
                { country: 'Zambia', code: 'ZM' },
                { country: 'Zimbabwe', code: 'ZW' },
            ],
            listOfCAStates: [
                { state_code: 'AB', state: 'Alberta' },
                { state_code: 'BC', state: 'British Columbia' },
                { state_code: 'MB', state: 'Manitoba' },
                { state_code: 'NB', state: 'New Brunswick' },
                { state_code: 'NL', state: 'Newfoundland and Labrador' },
                { state_code: 'NT', state: 'Northwest Territories' },
                { state_code: 'NS', state: 'Nova Scotia' },
                { state_code: 'NU', state: 'Nunavut' },
                { state_code: 'ON', state: 'Ontario' },
                { state_code: 'PE', state: 'Prince Edward Island' },
                { state_code: 'QC', state: 'Quebec' },
                { state_code: 'SK', state: 'Saskatchewan' },
                { state_code: 'YT', state: 'Yukon' }
            ],
            clubType: [],
            clubLogoImg: '/dist/img/avatarimg.png',
            alertMessage: "",
            displayAlert: "",
            alertVariant: "success",
            listOfDonationSportDiscipline: {
                "0": "Select",
                "1": "3-Event (AWSA) Under 14 Team ",
                "2": "3-Event (AWSA) Under 17 (Junior) Team ",
                "3": "3-Event (AWSA) Under 21 Team ",
                "4": "3-Event (AWSA) 35+ Team ",
                "5": "3-Event (AWSA) Elite Team ",
                "6": "Barefoot (ABC) Team ",
                "7": "Collegiate (NCWSA) Team ",
                "8": "Adaptive (USA-AWSWS) Team ",
                "9": "Hydrofoil (USHA) Event Development Fund",
                "10": "Kneeboard (AKA) Team ",
                "11": "Show Ski (NSSA) Team ",
                "12": "Ski Race (NWSRA) Team ",
                "13": "Wakeboard (USA-WB) Boat Team ",
                "14": "USA Water Ski & Wake Sports Foundation",
                "15": "USA Water Ski & Wake Sports General Fund",
                "16": "3-Event (AWSA) General Team Fund",
                "17": "Wakeboard (USA-WB) Cable Team",
                "18": "Wakeboard (USA-WB) Collegiate Team Fund",
            },
            showLoader: false,
            subdomain: "",
            properties: null,
            additionalInsuranceMemberOptions: [
                { text: 'Manager/Lessor of Premises (Landlord of Premises)', value: 'Manager/Lessor of Premises' },
                { text: 'Political Subdivision Permit', value: 'Political Subdivision Permit' },
                { text: 'Property Owner', value: 'Property Owner' },
                { text: 'Sponsor', value: 'Sponsor' },
                { text: 'Other', value: 'Other' }
            ],
            sportDisciplines: [],
            sportDisciplinesSelect2: [],
            groups: [],
            clubOptions: [],
            errors: {},
            updateLoggedUserInfo: 0,
            membershipData: [],
            financialClass: [],
            financialSubClass: [],
            sassClientIds: {},
            prop_general: {},
            stateClubs: [],
            clubDisciplinesList: [],
            membershipStatus: [],
            regionsList: [],
            yearArr: [],
            loginTime: '',
            groupCommittee: {
                screening: true,
                payment: true,
                minimum_age: true
            },
            returnurl: '',
            ignoreWaiverMembership: [],
            displayOfficialRatings: true,
            memberLeague: [],
            memberLeagueSelect2: [],
            collegeOptions: [],
            teamList: [],
            currentSeason: {},

        }
    },
    methods: {
        parseDatePick(dateString, format) {
            return fecha.parse(dateString, format);
        },
        redirectToMemberDashboard() {
            //this.$router.push({ name: 'book', params: { id: result.data.book.id }});
            this.$router.push({ path: '/member/dashboard' });
        },
        redirectToClubDashboard() {
            this.$router.push({ path: '/club/dashboard' });
        },
        usdate(value) {
            return (value == null) ? 'N/A' : moment(value).format("MM/DD/YYYY");
        },
        resetAlert() {
            this.displayAlert = false;
        },
        getDiscipline(typ) {
            axios.get(this.basePath + "api/getDisciplines/" + typ)
                .then(response => {
                    this.sportDisciplines = response.data.data;
                    let discipline_codes = Object.keys(this.sportDisciplines);
                    if (discipline_codes.length && this.sportDisciplinesSelect2.length == 0) {
                        discipline_codes.forEach(code => {
                            this.sportDisciplinesSelect2.push({ id: code, text: this.sportDisciplines[code] == "NA - N/A" ? "N/A" : this.sportDisciplines[code] });
                        });
                    }
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        getGroup() {
            axios.get(this.basePath + 'api/getGroup')
                .then(response => {
                    this.groups = response.data.data;
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        getMembershipStatus(sort = null)
        {
            let membershipStatusUrl = this.basePath+'api/getMembershipStatus';
            if(sort != null){
                membershipStatusUrl = this.basePath+'api/getMembershipStatus?order=a2z'
            }
             
            axios.get(membershipStatusUrl)
                .then(response => {
                    this.membershipStatus = response.data.data;
                    // if(sort != null){
                    //     let orderedMembership =[];
                    //     let keys = Object.keys(this.membershipStatus);
                    //     keys.map(key => {
                    //         orderedMembership.push({ id: this.membershipStatus[key], text: key });
                    //     });
                    //     this.membershipStatus = orderedMembership;
                    //     console.log('testStatus', this.membershipStatus);
                    // }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        getRegions() {
            axios.get(this.basePath + 'api/regions')
                .then((res) => {
                    this.regionsList = res.data.data;
                })
                .catch((error) => {
                    console.log('Error:' + error);
                    return false;
                })
        },
        getClubType() {
            axios.get(this.basePath + "api/getClubType")
                .then((response) => {
                    var clubTypes = response.data.data;
                    this.clubType = clubTypes;
                })
                .catch(error => {
                    console.log("error getting clubs types")
                })
        },
        getMembership() {
            axios.get(this.basePath + "api/getMemberships")
                .then((response) => {
                    this.membershipData = response.data.data;
                })
                .catch(error => {
                    console.log("error getting Membership info")
                })
        },
        getFinancialClass() {
            axios.get(this.basePath + "api/getFinancialClass")
                .then((response) => {
                    this.financialClass = response.data.data;
                })
                .catch(error => {
                    console.log("error getting Financial Class info")
                })
        },
        getFinancialSubClass() {
            axios.get(this.basePath + "api/getFinancialSubClass")
                .then((response) => {
                    this.financialSubClass = response.data.data;
                })
                .catch(error => {
                    console.log("error getting Financial Sub Class info")
                })
        },
        validateDate(date) {
            return moment(date, "MM/DD/YYYY", true).isValid();
        },
        getSiteProps(name) {
            return name.split('.').reduce((o, k) => (o || {})[k], this.properties);
        },
        getClubs() {
            axios.get(this.basePath + "api/get_teams")
                .then((response) => {
                    var clubs = response.data.data;
                    this.clubOptions = clubs;
                })
                .catch(error => {
                    console.log("error geting clubs list")
                })
        },
        getClubDisciplines() {
            axios.get(this.basePath + "api/getClubDisciplines").then(res => {
                this.clubDisciplinesList = res.data.data;
            }).catch(err => {
                console.log('Error:' + err);
            });
        },
        getStateClubs() {
            axios.get(this.basePath + 'api/getStateClubs')
                .then(response => {
                    this.stateClubs = response.data.data;
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        cardYears() {
            const year = new Date().getFullYear();
            this.yearArr = Array.from({ length: 12 }, (value, index) => year + index);
        },
        isAlphabets: function(evt) {

            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 64 && charCode < 122) {
                return true;
            } else {
                evt.preventDefault();
            }
        },
        removeCommaFromNumber(number) {
            return parseFloat(number.toString().replace(/,(?=.*\d+)/g, ''));
        },
        setupPageTitles() {
            document.title = this.$route.meta.title || this.getSiteProps('general.site_name') || 'iSportz';
        },
        requiredTextField(name, fieldName = 'This field') {
            if (name == undefined) {
                return { valid: false, error: fieldName + " is required" };
            } else if (name == "") {
                return { valid: false, error: fieldName + " is required" };
            }
            return { valid: true, error: null };
        },
        sessionDuration() {
            this.sessionTimer = setInterval(() => { this.checkSession(); }, 30 * 1000);
        },
        logoutSession() {
            this.loginTime = localStorage.getItem('time');
            let nowTime = moment().unix();
            let sessionValue = (this.loginExpirationDuration * 60);
            if (this.loginTime != null) {
                if ((nowTime - this.loginTime) >= sessionValue) {
                    if (localStorage.getItem('membertoken') != null) {
                        if (this.subdomain=='waterski' && localStorage.getItem('member_type') == 7) {
                            this.removeLocalStorageAndRouting('membertoken', 'member', '/club/login');
                        } else {
                            this.removeLocalStorageAndRouting('membertoken', 'member', '/member/login');
                        }
                    } else if (localStorage.getItem('clubToken') != null) {
                        this.removeLocalStorageAndRouting('clubToken', 'team', '/club/login');
                    } else if (localStorage.getItem('admintoken') != null) {
                        this.removeLocalStorageAndRouting('admintoken', 'admin', '/login');
                    }
                } else {
                    localStorage.setItem('time', nowTime);

                }
            }
        },
        checkSession() {
            this.loginTime = localStorage.getItem('time');
            let nowTime = moment().unix();
            let sessionValue = (this.loginExpirationDuration * 60);
            if (this.loginTime != null) {
                if ((nowTime - this.loginTime) >= sessionValue) {
                    this.logoutSession();
                }
            }

        },
        removeLocalStorageAndRouting(token, user, route) {
            localStorage.removeItem(token);
            localStorage.removeItem(user);
            this.$router.push(route);
        },
        isNumber: function(evt) {
            if (evt != '') {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        },
        googleAnalytics() {
            let trackId = this.getSiteProps('general.google_analytics');
            let Script = document.createElement("script");
            Script.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + trackId);
            document.head.appendChild(Script);
            window.dataLayer = window.dataLayer || [];

            function gtag() { window.dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', trackId);
        },
        sortedDonations(obj) {
            const ret = [];
            if (obj != "") {
                var arr = Object.values(obj);
                if (this.subdomain == 'waterski') {
                    var firstElement = arr.shift();
                    arr = arr.sort();
                    // var lastElement = arr.pop();
                    arr = [firstElement, ...arr].forEach(val => {
                        const key = Object.keys(obj).find(key => obj[key] == val)
                        ret.push({ id: key, name: val })
                    });
                } else {
                    arr = arr.forEach(val => {
                        const key = Object.keys(obj).find(key => obj[key] == val)
                        ret.push({ id: key, name: val })
                    });
                }
            }
            return ret;
        },
        shouldSkipWaivers() {
            const retry2 = setInterval(() => {
                this.ignoreWaiverMembership = this.getSiteProps('memberRegistration.ignore_waivers_memberships');
                if (this.ignoreWaiverMembership !== undefined && this.ignoreWaiverMembership.length) {
                    clearInterval(retry2);
                }
            }, 200);
        },
        isChildDetach(dob, date) {
            dob = moment(new Date(dob)).format("YYYY-MM-DD");
            date = moment(new Date(date)).format("YYYY-MM-DD");
            let childAgeValid = this.getSiteProps('memberRegistration.family.fields.family.popup.birthDay.child_age');
            return moment(date, "YYYY-MM-DD").diff(dob, "years") >= childAgeValid;
        },
        getLeagues() {
            axios.get(this.basePath + "api/league")
                .then(response => {
                    this.memberLeague = response.data.data;
                    let memberLeague_codes = Object.values(this.memberLeague);
                    if (memberLeague_codes.length && this.memberLeagueSelect2.length == 0) {
                        memberLeague_codes.forEach(code => {
                            this.memberLeagueSelect2.push({ id: code.id, label: code.name, value: code.state });
                        });
                    }
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        getCollegeCommittedsList() {
            axios.get(this.basePath + "api/collegecommitted")
                .then((response) => {
                    let teams = response.data.data;
                    //this.collegeOptions= response.data.data;
                    let teamKey = Object.keys(teams);
                    if (teamKey.length) {
                        this.collegeOptions = Object.keys(teams).map((field) => {
                            return {
                                id: teams[field].id,
                                text: teams[field].name
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log("error geting clubs list")
                })
        },
        getTeamsList() {
            axios.get(this.basePath + "api/get_teams_division")
                .then((response) => {
                    let teams = response.data.data;
                    let teamKey = Object.keys(teams);
                    if (teamKey.length) {
                        this.teamList = Object.keys(teams).map((field) => {
                            return {
                                age: field.slice(0, -1),
                                value: teams[field]
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log("error geting clubs list")
                })
        },
        isUnderAgeDivision(birth_date) {
            var today = new Date(this.currentSeason.start_date);
            var birthDate = new Date(birth_date);
            var age = today.getFullYear() - birthDate.getFullYear();
            return age;
        },
        getCurrentSeason() {
            axios.get(this.basePath + "api/get_current_season")
                .then((response) => {
                    this.currentSeason = response.data.data;
                })
                .catch(error => {
                    console.log("error")
                })
        },
        getAgeSeasonWise(birth_date) {
            let birth_date_arr = birth_date.split('/');
            let today_date = (this.subdomain == 'alliancefastpitch')? new Date(this.currentSeason.start_date) : new Date();
            let today_year = today_date.getFullYear();
            let today_month = today_date.getMonth();
            let today_day = today_date.getDate();
            let age = today_year - birth_date_arr[2];

            if(this.subdomain != 'alliancefastpitch') {
                if (today_month < (birth_date_arr[0] - 1)) {
                    age--;
                }
                if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1])) {
                    age--;
                }
            }
            return age;
        }
    },
    async mounted() {
        if (this.subdomain == "") {
            this.sassClientIds = { "waterski": 1, "shooting": 2, "alliancefastpitch": 3 };

            const host = window.location.host;
            // const parts = host.split('.');
            // const domainLength = 3;

            // this.subdomain = parts.length == domainLength ? parts.shift() : "waterski";

            this.subdomain = "waterski";

            if (host.toLowerCase().includes("waterski")) this.subdomain = "waterski";
            else if (host.toLowerCase().includes("shooting")) this.subdomain = "shooting";
            else if (host.toLowerCase().includes("alliance")) this.subdomain = "alliancefastpitch";

            // this.subdomain = (this.subdomain == 'devportal' || this.subdomain == 'staging-portal')  ? 'waterski' : this.subdomain;

            let prefix_prop = this.subdomain || 'waterski';

            try {
                this.properties = await import ('./properties/' + prefix_prop + ".sites.properties");
            } catch (error) {
                console.log("Property File is not found...\n", error)
            }

            //Load CSS of particular properties file
            import ("./properties/" + prefix_prop + ".properties.css");

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    'ClientId': this.sassClientIds[this.subdomain]
                }
            });

            // if(this.subdomain == "shooting"){
            //     this.listOfDonationSportDiscipline = {
            //         "0":"Select",
            //         "1":"USA Shooting",
            //         "2":"USA Shooting Juniors",
            //         "3":"Para",
            //         "4":"Pistol",
            //         "5":"Rifle",
            //         "6":"Shotgun",
            //         "7":"Paralympic"
            //     };
            // }
            if (this.properties.memberRegistration.individual.fields.donation != undefined) {
                this.listOfDonationSportDiscipline = this.properties.memberRegistration.individual.fields.donation.disciplines.options;
            }
        }
        this.logoutSession();
        this.sessionDuration();
        this.googleAnalytics();
        this.shouldSkipWaivers();

        momentTimezone.tz.setDefault("UTC"); /***** Default Timezone setup for overall Application ********/

    },
    computed: {
        loginExpirationDuration: function() {
            return process.env.VUE_APP_SESSION_TIMEOUT;
        },
    },
    updated() {
        this.setupPageTitles();
        let props_favicon = '/' + this.getSiteProps('favicon.icon');
        if (props_favicon) {
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = props_favicon;

            link = document.querySelector("link[rel~='apple-touch-icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'apple-touch-icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = props_favicon;

            var meta = document.querySelector("meta[name~='msapplication-TileImage']");
            if (!meta) {
                meta = document.createElement('meta');
                meta.name = 'msapplication-TileImage';
                document.getElementsByTagName('head')[0].appendChild(meta);
            }
            meta.content = props_favicon;
        }
        this.prop_general = this.getSiteProps('general');
    }

});

import DatePick from 'vue-date-pick';
import { _ } from "core-js";
import fecha from "fecha";

function mergeRecursive(obj1, obj2) {
    for (var p in obj2) {
        try {
            // Property in destination object set; update its value.
            if (obj2[p].constructor == Object) {
                obj1[p] = mergeRecursive(obj1[p], obj2[p]);

            } else {
                obj1[p] = obj2[p];

            }

        } catch (e) {
            // Property in destination object not set; create it and set its value.
            obj1[p] = obj2[p];

        }
    }
    return obj1;
}
Vue.component('date-pick', mergeRecursive(DatePick, {
    methods: {
        addCloseEvents() {
            if (!this.closeEventListener) {
                this.closeEventListener = e => this.inspectCloseEvent(e);
                ['click', 'keyup'].forEach(
                    eventName => document.addEventListener(eventName, this.closeEventListener)
                );
            }
        },
    }
}));

// axios.interceptors.request.use(config => {
//     const host = window.location.host;
//     // const parts = host.split('.');
//     // const domainLength = 3;

//     var subdomain = "waterski";

//     if(host.toLowerCase().includes("waterski")) subdomain = "waterski";
//     else if(host.toLowerCase().includes("shooting")) subdomain = "shooting";

//     var clientIds = { "waterski": 1, "shooting": 2 };

//     config.headers.ClientId = clientIds[subdomain];
//     return config;
// }, error => {
//     return Promise.reject(error);
// });