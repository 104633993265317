<template>
  <div>
    <header class="header landing-header">
      <nav
        class="navbar navbar-expand-lg navbar-default navbar-fixed-top nav-bar-height"
      >
        <div class="container">
          <a v-animate-css="'zoomIn'" class="navbar-brand" href="/"
            ><img
              :src="this.getSiteProps('landingpage.logo')"
              v-if="this.getSiteProps('landingpage.logo')"
              style="width:100px;"
          /></a>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
          </button>
          <div class="navbar-collapse collapse" id="navbarCollapse" style="">
            <ul class="navbar-nav ml-auto menu-font">
              <!-- <li><a href="#home">Help</a></li> -->
              <li class="pr-4" v-animate-css="'zoomIn'">
               <a class="p-2" :href="'tel:'+this.getSiteProps('general.phone')"><span> <i class="fas fa-phone-alt pb-1"></i
                >{{ this.getSiteProps("general.phone") }}</span></a>
              </li>
              <li class="pr-4" v-animate-css="'zoomIn'">
                <a  class="p-2" :href="'mailto:'+this.getSiteProps('general.email')"><i class="fas fa-envelope"></i
                >{{ this.getSiteProps("general.email") }}</a>
              </li>
              <!-- <li><a href="#portfolio">Work</a></li> -->
              <!-- <li><a href="#team">Team</a></li> -->
              <li class="support-link" v-animate-css="'zoomIn'">
                <a class="p-2" href="help-and-support"><i class="fas fa-headset"></i>Support</a>
              </li>
              <li class="discover-link" v-animate-css="'zoomIn'">
                <a href="member/login" class="discover-btn">LOGIN</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <section class="home-section" v-if="bannerStatus" id="home" :style="{ backgroundImage: `url(${this.basePath+this.banner.image})` }">
      <div class="home-section-overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto text-center padding-top-120">
            <div class="banner-area" v-for="bannercontent in landingContent" :key="bannercontent.bannertitle">
              <h1 v-animate-css="'zoomIn'">
                {{ banner.title }}
              </h1>
              <!-- <p v-animate-css="'zoomIn'" class="hero-text">
                {{ bannercontent.bannersubtitle }}
              </p> -->
              <div class="newsletter_form_box">
                <p class="newsletter_success_box" style="display:none;">
                  {{ bannercontent.bannersubtitle }}
                </p>

                <div class="registernowlink">
                  <div class="btn-large btn-danger discover-btn">
                  <a style="color:white;" v-animate-css="'zoomIn'"
                    href="/memberregistration"
                    >{{joinButton}}</a
                  ><a style="color:white" v-animate-css="'zoomIn'"
                    href="/member/login"
                    >{{renewButton}}</a
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-white aboutus-section" v-if="aboutStatus">
      <div class="container">
        <div role="tabpanel" class="tab-pane" v-for="aboutcontent in aboutContents" :key="aboutcontent.title">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center mb-4 pl-0"  >
                <h2 class="section-title mb-4" v-animate-css="'slideInLeft'">
                 {{about.title}}
                </h2>
                <p
                  v-animate-css="'slideInLeft'"
                  class="text-justify"
                  v-html="about.content"
                >


<!--                  <a class="text-danger"
                    href="#"
                    >{{aboutcontent.linkcontent}}</a
                  >-->
                </p>  
<!--                <p v-animate-css="'slideInLeft'" class="text-justify">{{aboutcontent.content1}}</p>
                <p v-animate-css="'slideInLeft'" class="text-justify">{{aboutcontent.content2}}</p>              
                <p v-animate-css="'slideInLeft'" class="text-justify">{{aboutcontent.content3}}</p>              
                <p v-animate-css="'slideInLeft'" class="text-justify">{{aboutcontent.content4}}</p>              
                <p v-animate-css="'slideInLeft'" class="text-justify">{{aboutcontent.content5}}</p>
                <h6 v-animate-css="'slideInLeft'" class="text-left">{{aboutcontent.contentsubtitle}}</h6>
                <p v-animate-css="'slideInLeft'" class="text-left">{{aboutcontent.contentsubtext}}</p>
                <p v-animate-css="'slideInLeft'" class="text-left">{{aboutcontent.contentsubtext1}}</p>
                <p v-if="subdomain == 'waterski'" v-animate-css="'slideInLeft'">From the beginning, USA Water Ski & Wake Sports has had a dual mission of promoting the growth and development of recreational water skiing, and organizing and governing the sport of competitive water skiing. The largest and most active water ski federation in the world, USA Water Ski & Wake Sports has a paid staff of six people. Headquartered in Auburndale, Fla., the staff serves more than 11,000 members across the country. Nearly 80 percent of USA Water Ski & Wake Sports' members are involved in tournament competition each year; the remainder are recreational water skiers.</p>-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-5 mt-4 mob-center">
              <img
                v-animate-css="'slideInLeft'"
                :src="basePath+about.image"
                :rel="basePath+about.image"
                class="img-thumbnail"
              />
            </div>

            <div class="col-sm-12 col-md-8 col-lg-7 right-col">
              <ul class="benefits-list about-listitem">
                <li v-animate-css="'slideInRight'">
                  <p class="section-subtitle text-justify ml-0">
                   {{aboutcontent.imgcontent}}
                  </p>
                </li>
                <li>
                  <h4
                    v-animate-css="'slideInRight'"
                    class="members-initiative-list-head"
                  >
                    {{aboutcontent.imgcontenttitle}}
                  </h4>
                  <ul class="members-intiative-list">
                    <li
                      v-animate-css="'slideInRight'"
                      class="members-initiative-list-li ml-0 mr-0" v-for="benefits in about.content_list"
                      :key="benefits.id"
                    >
                      <i :class="'fa fa-'+benefits.icon"></i>{{benefits.title}}
                    </li>
<!--                    <li
                      v-animate-css="'slideInRight'"
                      class="members-initiative-list-li ml-0 mr-0"
                    >
                      <i class="fas fa-arrow-up"></i>{{aboutcontent.imglistitem2}}
                    </li>
                    <li
                      v-animate-css="'slideInRight'"
                      class="members-initiative-list-li ml-0 mr-0"
                    >
                      <i class="fas fa-shield-alt"></i> {{aboutcontent.imglistitem3}}
                    </li>
                    <li
                      v-animate-css="'slideInRight'"
                      class="members-initiative-list-li ml-0 mr-0"
                    >
                      <i class="far fa-address-card"></i>{{aboutcontent.imglistitem4}}
                    </li>
                    <li v-if="subdomain != 'waterski'"
                      v-animate-css="'slideInRight'"
                      class="members-initiative-list-li ml-0 mr-0"
                    >
                      <i class="fas fa-users"></i>{{aboutcontent.imglistitem5}}
                    </li>
                    <li  v-if="subdomain != 'waterski'"
                      v-animate-css="'slideInRight'"
                      class="members-initiative-list-li ml-0 mr-0"
                    >
                    <i class="fas fa-headphones-alt"></i>{{aboutcontent.imglistitem6}}
                    </li>
                    <li  v-if="subdomain != 'waterski'"
                      v-animate-css="'slideInRight'"
                      class="members-initiative-list-li ml-0 mr-0"
                    >
                    <i class="fas fa-tag"></i>{{aboutcontent.imglistitem7}}
                    </li>
                    <li  v-if="subdomain == 'alliancefastpitch'"
                         v-animate-css="'slideInRight'"
                         class="members-initiative-list-li ml-0 mr-0"
                    >
                      <i class="fas fa-tag"></i>{{aboutcontent.imglistitem8}}
                    </li>-->
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="joinTodayStatus" class="section-grey" id="about">
<!--      <div  v-for="membershipcontent in membershipContents" :key="membershipcontent.title">-->
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row">
         
          <!--begin col-md-12 -->
            <div class="col-md-12 text-center margin-bottom-10">
              <h2 class="section-title">{{joinToday.title}}</h2>
              <p class="section-subtitle mb-3">
                {{joinToday.content}}
              </p>
              <!-- <p class="section-subtitle">and connects the game at every level while promoting a lifelong love of the sport.</p> -->
            
          </div>
          <!--end col-md-12 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->

      <!--begin services-wrapper -->
      <div class="services-wrapper">
        <!--begin container -->
        <div class="container">
        <div class="row">
          <div class="col-md-4" v-for="join in joinToday.content_list" :key="join.id">
            <div class="main-services">
              <i :class="'fas fa-'+join.icon"></i>
              <h4>{{join.title}}</h4>
              <p>
                {{join.description}}
              </p>
            </div>
          </div>
          </div>
          <!--begin row -->
<!--          <div v-if="subdomain == 'shooting'" class="row">
            &lt;!&ndash;begin col-md-4 &ndash;&gt;
            <div class="col-md-4">
              <div class="main-services">
                <i class="far fa-user-circle"></i>
                <h4>{{membershipcontent.membershipheader1}}</h4>
                <p>
                  {{membershipcontent.membershipcontent1}}
                </p>
              </div>
            </div>
            &lt;!&ndash;end col-md-4 &ndash;&gt;

            &lt;!&ndash;begin col-md-4 &ndash;&gt;
            <div class="col-md-4">
              <div class="main-services red">
                <i class="far fa-heart"></i>
                  <h4>{{membershipcontent.membershipheader2}}</h4>
                <p>
                  {{membershipcontent.membershipcontent2}}
                </p>
              </div>
            </div>
            &lt;!&ndash;end col-md-4 &ndash;&gt;

            &lt;!&ndash;begin col-md-4 &ndash;&gt;
            <div class="col-md-4">
              <div class="main-services">
                <i class="fas fa-anchor"></i>

                <h4>{{membershipcontent.membershipheader3}}</h4>

                <p>
                  {{membershipcontent.membershipcontent3}}
                </p>
              </div>
            </div>
          </div>
          <div v-if="subdomain == 'alliancefastpitch'"  class="row">
                                &lt;!&ndash;end col-md-4 &ndash;&gt;


            <div class="col-md-4">
              <div class="main-services red">
                <i class="fas fa-user-friends"></i>
                  <h4>{{membershipcontent.membershipheader1}}</h4>
                <p>
                  {{membershipcontent.membershipcontent1}}
                </p>
              </div>
            </div>
            &lt;!&ndash;end col-md-4 &ndash;&gt;

            &lt;!&ndash;begin col-md-4 &ndash;&gt;
            <div class="col-md-4">
              <div class="main-services">
                <i class="fas fa-child"></i>
                  <h4>{{membershipcontent.membershipheader2}}</h4>
                <p>
                  {{membershipcontent.membershipcontent2}}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="main-services">
                <i class="far fa-question-circle"></i>
                  <h4>{{membershipcontent.membershipheader3}}</h4>
                <p>
                  {{membershipcontent.membershipcontent3}}
                </p>
              </div>
            </div>
           </div>-->
          <!--end row -->
        </div>
        <!--end container -->
      </div>
      <!--end services-wrapper -->
<!--      </div>-->
    </section>

    <section class="section-white" v-if="videoStatus">
      <!--begin container-->
      <div class="container">        
<!--         <div class="row" v-if="subdomain =='waterski'">
          <div class="col-md-12 text-center mb-5">
            <h2 class="section-title">USA Water Ski & Wake Sports</h2>
          </div>
        </div>-->
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <h2 class="section-title">{{video.title}}</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
              <div class="youtube-embed">
            <iframe width="100%" height="540" :src="video.image" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
<!--          <div v-if="subdomain == 'waterski'">
             <div class="youtube-embed">
            <iframe width="100%" height="540" src="https://www.youtube.com/embed/NNlfiR5hsZg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div v-if="subdomain == 'alliancefastpitch'">
             <div class="youtube-embed">
               <iframe width="100%" height="540" src="https://www.youtube.com/embed/tz4Jo6xaWZA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>     -->
          </div>
        </div>
        

    </div>
      <!--end container-->
    </section>

    <section class="partners-section section-grey" v-if="sponsorsStatus">
      <!--begin container-->
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h4 class="partners-title text-center">{{sponsors.title}}</h4>
          </div>
        </div>

        <!--begin row-->
        <div class="row">
          <!--begin col-sm-12-->
          <div class="col-md-12 mx-auto text-center partners">
            <img
                class="m-3"
                :src="basePath+sponsor.url"
                style="width:150px;" v-for="sponsor in sponsors.content_list" :key="sponsor.id"
            />
            </div>
          </div>
<!--        <div class="row" v-if="subdomain != 'alliancefastpitch'">
          &lt;!&ndash;begin col-sm-12&ndash;&gt;
          <div class="col-md-12 mx-auto text-center partners">
             <img
              class="m-3"
              :src="this.getSiteProps('landingpage.award1')"
              v-if="this.getSiteProps('landingpage.award1')"
              style="width:150px;"
            />
            <img
              class="m-3"
              :src="this.getSiteProps('landingpage.award2')"
              v-if="this.getSiteProps('landingpage.award2')"
              style="width:150px;"
            />
            <img
              class="m-3"
              :src="this.getSiteProps('landingpage.award3')"
              v-if="this.getSiteProps('landingpage.award3')"
              style="width:150px;"
            />
          </div>
          &lt;!&ndash;end col-sm-12&ndash;&gt;
        </div>
        <div  class="row" v-else>
            &lt;!&ndash;begin col-sm-12&ndash;&gt;
            <div class="col-md-12 mx-auto text-center partners" >
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award1')"
                  v-if="this.getSiteProps('landingpage.award1')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award2')"
                  v-if="this.getSiteProps('landingpage.award2')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award3')"
                  v-if="this.getSiteProps('landingpage.award3')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award4')"
                  v-if="this.getSiteProps('landingpage.award4')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award5')"
                  v-if="this.getSiteProps('landingpage.award5')"
                  style="width:150px;"
              />
            </div>
            <div class="col-md-12 mx-auto text-center partners">
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award6')"
                  v-if="this.getSiteProps('landingpage.award6')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award7')"
                  v-if="this.getSiteProps('landingpage.award7')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award8')"
                  v-if="this.getSiteProps('landingpage.award8')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award9')"
                  v-if="this.getSiteProps('landingpage.award9')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award10')"
                  v-if="this.getSiteProps('landingpage.award10')"
                  style="width:150px;"
              />
            </div>
            <div class="col-md-12 mx-auto text-center partners">
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award11')"
                  v-if="this.getSiteProps('landingpage.award11')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award12')"
                  v-if="this.getSiteProps('landingpage.award12')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award13')"
                  v-if="this.getSiteProps('landingpage.award13')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award14')"
                  v-if="this.getSiteProps('landingpage.award14')"
                  style="width:150px;"
              />
              <img
                  class="m-3"
                  :src="this.getSiteProps('landingpage.award15')"
                  v-if="this.getSiteProps('landingpage.award15')"
                  style="width:150px;"
              />
            </div>
            &lt;!&ndash;end col-sm-12&ndash;&gt;
        </div>-->
        <!--end row-->
      </div>
      <!--end container-->
    </section>

    <div class="footer">
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="">
          <!--begin col-md-4 -->
          <!-- <div class="col-md-6 footer-font-size">
          <p>
          {{
          this.getSiteProps("login.copyright") +
          " " +
          new Date().getFullYear()
          }}
          </p>
          </div> -->
          <!--end col-md-4 -->

          <!--begin col-md-4 -->
          <!-- <div class="col-md-4">
					
                    <ul class="footer_social">

                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>

                        <li><a href="#"><i class="fab fa-pinterest"></i></a></li>

                        <li><a href="#"><i class="fab fa-facebook-square"></i></a></li>

                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>

                        <li><a href="#"><i class="fab fa-skype"></i></a></li>

                        <li><a href="#"><i class="fab fa-dribble"></i></a></li>

                    </ul>
                    
				</div> -->
          <!--end col-md-4 -->

          <!--begin col-md-4 -->
          <!-- <div class="col-md-6 footer-font-size">
            <p data-v-a3742dd8="" class="text-right">
              Powered by<img
                data-v-a3742dd8=""
                src="/dist/img/memberregistration/int-sports.png"
                alt="footer-img"
              />
            </p>
          </div> -->
               <div class="landingpageinner">
                        <div class="row footer-row">
                            <div class="col-md-8">
                                <div class="footeremail mt-1 col-md-12 pl-0" v-if="prop_footer.phone || prop_footer.email">
                                    <div class="">
                                        <div class=" ">
                                            <p class="footer-quest">Questions? Contact Member Services at <a :href="'tel:'+prop_footer.phone"> {{ prop_footer.phone }}</a> or <a :href="'mailto:'+prop_footer.email">{{ prop_footer.email }}</a></p>
                                        </div>
                                    </div>
                                </div>
                            <p class="text-danger">{{ this.getSiteProps('login.copyright') +' '+ new Date().getFullYear() }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="text-right mr-2 copyright">Powered by <a href="https://www.isportz.co/" target="_blank"><img class="ml-1" style="width:80px" v-bind:src="'/dist/img/logo.svg'" alt="footer-img" /></a></p>
                            </div>
                        </div>
                    </div>
          <!--end col-md-4 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomeNew",
    data() {
      return {
         landingContent:null,
         aboutContents:null,
         membershipContents:null,
         prop_footer: {},
         banner:[],
         about:[],
         joinToday:[],
         video:[],
         sponsors:[],
         aboutStatus:false,
         joinTodayStatus:false,
         videoStatus:false,
         sponsorsStatus:false,
         bannerStatus:false
      }
    },
  methods:{
    landingContents() {
      axios.get(this.basePath+'api/cmsSection/'+2)
          .then(function (response) {
                response.data.data.forEach(content => {
                  if (content.section_id == 2) {
                    this.about = content;
                    this.aboutStatus = true;
                  } else if(content.section_id == 3){
                    this.joinToday = content;
                    this.joinTodayStatus = true;
                  } else if(content.section_id == 4){
                    this.video = content;
                    this.videoStatus = true;
                  } else if(content.section_id == 5){
                    this.sponsors = content;
                    this.sponsorsStatus = true;
                  }


                });
                }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    bannerContents() {
      axios.get(this.basePath+'api/cmsSection/2/1')
          .then(function (response) {
            this.banner = response.data.data;
            this.bannerStatus = true;
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
  },
    async mounted() {
      let properties =  await import('@/properties/'+this.subdomain+".sites.properties");
      //console.log(properties.landingpage.slidercontent);     
      this.landingContent = properties.landingpage.slidercontent;
      this.aboutContents = properties.landingpage.about;
      this.membershipContents =  properties.landingpage.landingmembership;
      console.log(this.membershipContents);
      this.landingContents();
      this.bannerContents();
    },
           updated(){
           
            this.prop_footer = this.getSiteProps('memberRegistration.footer');
        },
    computed: {
      joinButton(){
       let btnAray = this.landingContent[0].bannerbtn.split('/');
          return btnAray[0];
      },
      renewButton(){
        let btnAray = this.landingContent[0].bannerbtn.split('/');
        return (btnAray[1])?'/'+btnAray[1]:'';
      }
    }
  }
</script>
<style scoped>
.container {
  max-width: 1140px !important;
}
.alternativetab .tab-content > .active {
  background: transparent;
}
.registernowlink a.discover-btn, .registernowlink .discover-btn {
  display: inline-block;
  font-size: 22px;
  border-radius: 50px !important;
  padding: 10px 43px !important;
  width: 30%;
}
ul {
  padding: 0;
}
.features-second.contenttab {
  padding: 10px 10px 5px;
  margin-bottom: 10px;
  border: 1px solid #f4f5f6 !important;
}
.contenttab .dropcaps-circle {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
}

.contenttab h4.margin-bottom-5 {
  font-size: 16px;
}

.contenttab.features-second p {
  font-size: 14px;
  line-height: 21px;
}
ul,
ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #858585;
  line-height: 24px;
  margin-bottom: 20px;
}

ul li,
ol li {
  font-size: 17px;
  line-height: 28px;
  font-family: "Open Sans", sans-serif;
  list-style-type: none;
}
.features-second {
  clear: both;
}
ul li i {
  vertical-align: middle;
  padding-right: 5px;
}

p {
  font-size: 17px;
  line-height: 28px;
  font-weight: 400;
  color: #4d4d4d;
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
}
.registernowlink .discover-btn a:hover{
  text-decoration: underline !important;
} 

a,
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  outline: 0;
}

a:hover {
  color: #017df7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 20px;
  color: #071e55;
  font-weight: 700;
}

h1 {
  font-size: 100px;
}

h2 {
  font-size: 38px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

h2.section-title {
  margin-bottom: 10px;
}

p.section-subtitle {
  font-size: 18px;
  color: #4d4d4d;
}

p.section-subtitle.white {
  color: #ffffff;
}

p.section-subtitle.black {
  color: #252525;
}

iframe {
  max-width: 100%;
  border: none;
}

.iframe-youtube {
  position: relative;
  margin-top: 30px;
  z-index: 100;
}

.iframe-youtube iframe {
  padding: 10px 10px;
  box-shadow: 0 15px 36px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  webkit-border-radius: 8px 8px;
  -moz-border-radius: 8px 8px;
  border-radius: 8px 8px;
  z-index: 100;
}

.popup-gallery:hover .video-popup-image {
  opacity: 1;
}

.video-play-icon {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  margin-top: -45px;
  margin-left: -45px;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 40px;
  color: #007bff;
  background-color: rgba(0, 123, 255, 0.7);
  border-radius: 50% 50%;
  display: inline-block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.tab-content > .active {
  background: #ffffff;
}
.video-play-icon:hover {
  background-color: rgba(0, 123, 255, 0.99);
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.video-play-icon i {
  color: #fff;
}

.white-text {
  color: #fff;
}

.red {
  color: #b83f40;
}

.strong {
  font-weight: bold;
}

.section-bg-1 {
  /* background: url(../images/bg3.jpg); */
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  position: relative;
  padding: 110px 0;
}

.section-bg-2 {
  /* background: url(../images/bg3.jpg); */
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  position: relative;
  padding: 120px 0;
}

.section-bg-3 {
  /* background: url(../images/bg1.jpg); */
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  position: relative;
  padding: 140px 0;
}

.section-bg-overlay {
  width: 100%;
  background: #b83f40;
  opacity: 0.95;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.section-white {
  background: #fff;
  padding: 90px 0 100px 0;
}

.section-grey {
  background: #f5f6f7;
  padding: 90px 0 100px 0;
}

.section-red {
  background: #b83f40;
  padding: 90px 0 100px 0;
}

.section-white.no-padding,
.section-grey.no-padding,
.section-red.no-padding {
  padding: 0;
}

.section-white.medium-paddings,
.section-grey.medium-paddings,
.section-red.medium-paddings {
  padding: 60px 0;
}

.section-white.small-paddings,
.section-grey.small-paddings,
.section-red.small-paddings {
  padding: 30px 0;
}

.section-white.xsmall-paddings,
.section-grey.xsmall-paddings,
.section-red.xsmall-paddings {
  padding: 10px 0;
}

.section-white.small-padding-top,
.section-grey.small-padding-top,
.section-red.small-padding-top {
  padding: 30px 0 90px 0;
}

.section-white.small-padding-bottom,
.section-grey.small-padding-bottom,
.section-red.small-padding-bottom {
  padding: 90px 0 70px 0;
}

.section-white.no-padding-bottom,
.section-grey.no-padding-bottom,
.section-red.no-padding-bottom {
  padding: 90px 0 0 0;
}

.small-col-inside {
  margin: 90px 0;
  max-width: 575px;
  padding-left: 55px;
}

.width-100 {
  width: 100%;
}

.box-shadow {
  border-radius: 8px;
  box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.15);
}

.image-shadow {
  border-radius: 8px 8px;
  box-shadow: 10px 12px 40px rgba(0, 0, 0, 0.2);
}
ul.navbar-nav.ml-auto {
  margin-top: 15px;
}

/* margin and padding classes */

.margin-right-15 {
  margin-right: 15px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-right-25 {
  margin-right: 25px !important;
}

.margin-left-25 {
  margin-left: 25px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-top-120 {
  margin-top: 120px !important;
}

.margin-top-110 {
  margin-top: 110px !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.margin-top-90 {
  margin-top: 90px !important;
}

.margin-top-80 {
  margin-top: 80px !important;
}

.margin-top-70 {
  margin-top: 70px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-35 {
  margin-top: 35px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-bottom-70 {
  margin-bottom: 70px !important;
}

.margin-bottom-80 {
  margin-bottom: 80px !important;
}

.margin-bottom-100 {
  margin-bottom: 100px !important;
}

.margin-bottom-120 {
  margin-bottom: 120px !important;
}

.margin-bottom-130 {
  margin-bottom: 120px !important;
}

.margin-bottom-140 {
  margin-bottom: 140px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-top-35 {
  padding-top: 35px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-top-45 {
  padding-top: 45px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}
.padding-top-60 {
  padding-top: 60px !important;
}

.padding-top-70 {
  padding-top: 70px !important;
}

.padding-top-80 {
  padding-top: 80px !important;
}

.padding-top-100 {
  padding-top: 100px !important;
}

.padding-top-120 {
  padding-top: 120px !important;
}

.padding-top-150 {
  padding-top: 150px !important;
}

.padding-bottom-70 {
  padding-bottom: 70px !important;
}

.padding-bottom-60 {
  padding-bottom: 60px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.padding-left-20 {
  padding-left: 20px;
}

/* ========================================================== */
/* 			               02. MENU-NAV                       */
/* ========================================================== */

.navbar-brand {
  font-family: "Nunito", sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #b83f40;
}

.navbar-brand:hover {
  color: #b83f40;
}

.navbar-fixed-top.opaque .navbar-brand {
  color: #b83f40;
}

.navbar-fixed-top.opaque .navbar-brand:hover {
  color: #b83f40;
}

.navbar {
  width: 100%;
  z-index: 9999;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar-toggler {
  border: 1px solid #b83f40;
}

.navbar-toggler-icon {
  width: 1.3em;
  height: 1.3em;
}

.navbar-toggler-icon i {
  color: #b83f40;
  vertical-align: middle;
}

.navbar-fixed-top {
  position: absolute !important;
  background-color: #fff;
  border-bottom: none;
  transition: background-color 0.25s ease 0s;
}

.navbar-fixed-top.opaque {
  position: fixed !important;
  top: 0;
  margin-top: 0;
  background-color: #fff !important;
  border-bottom: 1px solid #efefef;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04) !important;
  transition: all 0.25s ease-in-out 0s;
}

.navbar-fixed-top .navbar-nav > li > a {
  color: #071e55;
  font-size: 17px !important;
  line-height: 30px !important;
  padding: 10px 15px !important;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}

.navbar-fixed-top.opaque .navbar-nav > li > a {
  color: #071e55;
}

.navbar-fixed-top .navbar-nav > li > a:hover {
  color: #b83f40 !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.navbar-fixed-top.opaque .navbar-nav .current a {
  color: #b83f40 !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.registernowlink a.discover-btn,
.registernowlink .discover-btn,
.navbar-fixed-top .navbar-nav > li > a.discover-btn {
  border: 1px solid #e31736;
  color: #fff !important;
  background: #e31736;
  border-radius: 24px 24px;
  padding: 12px 30px !important;
  margin-left: 10px;
  margin-right: 15px;
  font-family: "Nunito", sans-serif;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.navbar-fixed-top .navbar-nav > li > a.discover-btn:hover {
  color: #e31736 !important;
  background: transparent;
  border: 1px solid #e31736;
  text-decoration: none;
}

.navbar-fixed-top.opaque .navbar-nav > li > a.discover-btn {
  color: #fff !important;
  background: #e31736;
  border: 1px solid #e31736;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.navbar-fixed-top.opaque .navbar-nav > li > a.discover-btn:hover {
  border: 1px solid #b83f40;
  color: #b83f40 !important;
  background: transparent;
}

/* ========================================================== */
/* 			                03. FOOTER                        */
/* ========================================================== */

.footer {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 10px 0px;
  background-color: #ffffff;
  border-top: 1px solid #b83f40;
}

.footer p {
  line-height: 24px;
  color: #454545 !important;
  font-size: 11px;
}
.footeremail p.footer-quest a {
    color: #e31736 !important;
    text-decoration: none;
    font-size: 11px !important;
} 
.footer p > a {
  font-size: 17px;
  color: #b83f40;
  text-decoration: underline;
}

.footer p > a:hover {
  color: #b83f40;
  text-decoration: underline;
}

span.template-name {
  font-weight: bold;
}

a.footer-links {
  display: block;
  font-size: 17px;
  margin-bottom: 2px;
  color: #454545;
}

a.footer-links:hover {
  color: #b83f40;
  text-decoration: underline;
  transition: all 0.5s ease-in-out;
}

/* Footer Social Icons */
ul.footer_social {
  float: right;
}

ul.footer_social li {
  display: inline-block;
  margin: 0;
  line-height: 100% !important;
}

ul.footer_social li a i {
  display: inline-block;
  margin: 0 4px;
  padding: 0;
  font-size: 20px !important;
  line-height: 26px !important;
  color: #1e90ff;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

ul.footer_social li a i.fa-twitter {
  color: #4099ff;
}

ul.footer_social li a i.fa-pinterest {
  color: #c92228;
}

ul.footer_social li a i.fa-facebook-square {
  color: #3b5998;
}

ul.footer_social li a i.fa-instagram {
  color: #8a3ab9;
}

ul.footer_social li a i.fa-skipe {
  color: #00aff0;
}

ul.footer_social li a i.fa-dribble {
  color: #ea4c89;
}

ul.footer_social li a i:hover {
  color: #b83f40 !important;
}

/* ========================================================== */
/* 			               04. HOME                           */
/* ========================================================== */

.home-section {
  position: relative;
  z-index: 999;
  padding-top: 240px;
  padding-bottom: 240px;
  background-size: cover;
}

.home-section-overlay {
  width: 100%;
  opacity: 0.45;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.home-section h1 {
  margin-bottom: 20px;
  line-height: 1.2;
  color: #fff;
  text-shadow: 1px 1px #373737;
}

.home-section p.hero-text {
  /* margin-bottom: 35px; */
  /* font-size: 20px; */
  line-height: 30px;
  font-weight: 400;
  color: #fff;
  text-shadow: 1px 1px #373737;
  margin-bottom: 8px;
}

/* -----  Newsletter ----- */
.newsletter_form_wrapper {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.newsletter_form_box {
  margin: 0 auto;
  max-width: 730px;
}

#newsletter-form #email_newsletter {
  width: 100%;
  max-width: 410px;
  height: 59px;
  padding: 0 20px;
  border: 1px solid #bebebe;
  background-color: #fff;
  moz-border-radius: 30px 30px;
  -webkit-border-radius: 30px 30px;
  border-radius: 30px 30px;
  color: #454545;
  font-size: 17px;
  margin: 0 10px 20px 0;
}

#newsletter-form #submit-button-newsletter {
  width: 145px;
  height: 59px;
  background: #b83f40;
  border: none;
  color: #fff;
  display: inline;
  margin: 0 0 0 -5px;
  moz-border-radius: 30px 30px;
  -webkit-border-radius: 30px 30px;
  border-radius: 30px 30px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Cabin", sans-serif;
  vertical-align: top;
  transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -webkit-transition: background-color 0.25s ease-in-out;
}

#newsletter-form #submit-button-newsletter:hover {
  cursor: pointer;
  background-color: #df2931;
  transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -webkit-transition: background-color 0.25s ease-in-out;
}

input#email_newsletter::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
}
input#email_newsletter:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
}
input#email_newsletter::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
}
input#email_newsletter:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
}

.newsletter_success_box {
  font-size: 18px;
  font-style: italic;
  color: #fff;
  margin: 5px 0;
}

/* Pertners */
.partners-section {
  padding: 40px 0 40px 0;
  background-color: #f5f6f7;
}

.partners img.partners {
  opacity: 1;
  margin: 10px 15px;
  max-height: 100px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.partners img.partners:hover {
  opacity: 0.8;
}

.partners-title {
  font-size: 28px;
  line-height: 78px;
  display: block;
  margin-right: 15px;
}

/* ========================================================== */
/* 			               05. TEAM                           */
/* ========================================================== */

.team-item {
  padding: 10px 25px 30px 25px;
  background-color: #fff;
  border: 1px solid #e5efff;
  border-top: none;
  text-align: center;
  transition: all 1s;
  border-radius: 0 0 8px 8px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.team-item h3 {
  margin: 20px 0 1px 0 !important;
  color: #454545;
  font-size: 18px;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

.team-info {
  display: block;
  margin-bottom: 0;
}

.team-info p {
  color: #acacac;
  font-style: italic;
}

.team-info::after {
  background: #b83f40;
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 50px;
  height: 3px;
  margin-top: -20px;
}

.team-img {
  border-radius: 8px 8px 0 0;
}

/* ========================================================== */
/* 			                06. FEATURES                      */
/* ========================================================== */

.services-wrapper {
  position: relative;
}

.main-services {
  position: relative;
  margin: 10px 5px;
  overflow: hidden;
  text-align: center;
  transition: all 1s;
}

.main-services:hover {
  transform: scale(1.025);
  transition: all 1s;
}

.main-services img {
  width: 100%;
  max-width: 200px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.main-services h3 {
  margin-bottom: 5px;
}

.main-services h3 a {
  font-size: 22px;
  font-weight: 700;
  color: #b83f40;
}

.main-services h4 {
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: 700;
}

.main-services p {
  padding: 0 10px;
  margin-bottom: 15px;
}

.main-services i {
  margin-bottom: 15px;
  display: inline-block;
  padding: 16px 16px;
  font-size: 40px;
  line-height: 46px;
  width: 75px;
  height: 75px;
  text-align: center;
  color: #fff;
  border-radius: 20px;
  background-color: #b83f40;
}

.main-services.red i {
  background-color: #b83f40;
}

.main-services.blue i {
  background-color: #1e90ff;
}

.main-services.green i {
  background-color: #27b36a;
}

blockquote {
  font-size: 16px;
  color: #4285f4;
  border-left: 2px solid #4285f4;
}

.faq-box {
  margin: 30px 0 0 0;
  transition: all 1s;
}

.faq-box h4 {
  font-size: 22px;
  margin-bottom: 10px;
}

.faq-box h5 {
  margin-bottom: 7px;
}

.faq-box p {
  color: #7c8595;
}

/* Features */
.feature-box {
  overflow: hidden;
  margin: 40px 0 0 0;
}

.feature-box i {
  float: left;
  font-size: 50px;
  line-height: 50px;
  width: 55px;
  height: 55px;
  text-align: center;
  color: #50a0d2;
}

.feature-box.light-blue i {
  color: #50a0d2;
}

.feature-box.dark-blue i {
  color: #3473b8;
}

.feature-box.light-green i {
  color: #a9d129;
}

.feature-box.dark-green i {
  color: #87ac34;
}

.feature-box.orange i {
  color: #fe9c24;
}

.feature-box.light-red i {
  color: #fc6a3f;
}

.feature-box.pink i {
  color: #ea4c89;
}

.feature-box-text {
  float: left;
  width: 75%;
  margin-left: 30px;
}

.feature-box h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.feature-box p {
  color: #7c8595;
}

/* ========================================================== */
/* 						   07. PORTFOLIO                      */
/* ========================================================== */

.gallery-insta {
  margin: 0 !important;
}

.gallery-insta img {
  border-radius: 0 !important;
  padding: 5px 5px;
}

p.follow-instagram a {
  color: #b83f40;
}

.popup-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
}

.popup-gallery {
  display: inline-block;
  position: relative;
}

.popup-gallery img {
  position: relative;
  z-index: 10;
  width: 100%;
  height: auto;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.popup-gallery:hover img {
  opacity: 0.25;
}

.popup-gallery a span.eye-wrapper,
.popup-gallery a span.eye-wrapper2 {
  background-color: #b83f40;
  position: absolute;
  display: block;
  overflow: hidden;
  z-index: 2;
  width: 90%;
  height: 90% !important;
  top: 5%;
  left: 5%;
  font-size: 22px;
  color: #fff;
  text-align: center;
  font-weight: 300;
  opacity: 0;
}

.popup-gallery a span.eye-wrapper i.eye-icon,
.popup-gallery a span.eye-wrapper2 i.eye-icon {
  position: absolute;
  display: inline-block;
  font-size: 36px;
  z-index: 3;
  top: 50% !important;
  margin-top: -18px !important;
  left: 0%;
  right: 0%;
}

.popup-gallery a:hover span {
  opacity: 1;
}

.popup-gallery img,
.popup-gallery a span {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.gallery-item {
  margin-bottom: 37px;
}

/* ========================================================== */
/* 			               08. PRICING                        */
/* ========================================================== */

.price-box-white {
  text-align: center;
  background-color: #fff;
  border: 1px solid #dde9fb;
  box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05);
  padding: 50px 20px;
  width: 90%;
  margin-top: 30;
  margin-bottom: 20px;
  margin-left: 20px;
  border-radius: 8px 8px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.price-box-grey {
  text-align: center;
  background: #b83f40;
  border: 2px solid #b83f40;
  box-shadow: 10px 12px 40px rgba(0, 0, 0, 0.2);
  padding: 50px 20px;
  width: 90%;
  margin-top: 30;
  margin-bottom: 20px;
  margin-left: 20px;
  border-radius: 8px 8px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.price-box-grey:hover,
.price-box-white:hover {
  transform: scale(1.03, 1.03);
  -ms-transform: scale(1.03, 1.03); /* IE 9 */
  -webkit-transform: scale(1.03, 1.03); /* Safari */

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.price-box-white .price-title,
.price-box-white ul.pricing-list li.price-value {
  color: #292929;
}

.price-box-grey .price-title,
.price-box-grey ul.pricing-list li.price-value {
  color: #fff;
}

.price-box-white .price-subtitle,
.price-box-white .price-text {
  color: #6a6a6a;
}

.price-box-grey .price-subtitle,
.price-box-grey .price-text {
  color: #fff;
}

ul.pricing-list li.price-title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 1px;
}

ul.pricing-list li.price-value {
  font-family: "Roboto", sans-serif;
  font-size: 70px;
  line-height: 70px;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
}

ul.pricing-list li.price-subtitle {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

ul.pricing-list li.price-text {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 5px;
}

ul.pricing-list li.price-tag a {
  color: #b83f40;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 23px 23px;
  padding: 15px 30px;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin: 40px 0 30px 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

ul.pricing-list li.price-tag a:hover {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

/* ========================================================== */
/* 			                 09. CONTACT                      */
/* ========================================================== */

.contact-form-wrapper {
  margin-top: 25px;
}

.contact-form-wrapper h3 {
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 500;
}

.contact-form-wrapper p {
  margin-bottom: 25px;
  font-size: 17px;
}

input.contact-input,
select.contact-input {
  width: 100%;
  height: 56px;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 15px;
  color: #fff;
  border: 2px solid #fff;
  background-color: transparent;
  font-size: 16px;
  -webkit-border-radius: 5px 5px;
  -moz-border-radius: 5px 5px;
  border-radius: 5px 5px;
}

textarea {
  height: 145px;
  margin-bottom: 12px;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  font-size: 16px;
  -webkit-border-radius: 5px 5px;
  -moz-border-radius: 5px 5px;
  border-radius: 5px 5px;
}

input.contact-submit {
  background: #fff;
  border: none;
  color: #b83f40;
  letter-spacing: 1px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  max-width: 300px;
  padding: 20px 0;
  text-transform: uppercase;
  -webkit-border-radius: 3px 3px;
  -moz-border-radius: 3px 3px;
  border-radius: 3px 3px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

input.contact-submit:hover {
  background: #eaeaea;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

input.contact-input::-webkit-input-placeholder,
textarea.contact-input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}
input.contact-input:-moz-placeholder,
textarea.contact-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}
input.contact-input::-moz-placeholder,
textarea.contact-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}
input.contact-input:-ms-input-placeholder,
textarea.contact-input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

#contact-form input.contact-input:focus,
#contact-form textarea:focus {
  border-color: #fff !important;
  color: #fff !important;
  outline: none;
}

#contact-form select.contact-input:focus {
  border-color: #fff !important;
  color: #454545 !important;
  outline: none;
}

p.contact_success_box {
  display: inline-block;
  color: #fff;
  border: 1px solid #eee;
  padding: 25px 25px;
  font-size: 19px;
  text-shadow: none;
  font-style: italic;
  border-radius: 8px 8px;
  margin: 15px 0;
}

/* ========================================================== */
/* 			               11. OTHER ELEMENTS                 */
/* ========================================================== */

/* a) Buttons */
.btn-white {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #b83f40;
  background-color: #ffffff;
  border: 1px solid #fff;
  box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.37);
  border-radius: 23px 23px;
  margin: 10px 10px;
  padding: 16px 26px;
  display: inline-block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.btn-white:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.5);
  color: #b83f40 !important;
  background: #f9f9f9;
}

.btn-white-border {
  color: #fff;
  background: transparent;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  border: 1px solid #fff;
  border-radius: 23px 23px;
  margin: 10px 10px;
  padding: 16px 26px;
  display: inline-block;
  box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.37);
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.btn-white-border:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.5);
  color: #b83f40 !important;
  background: #f9f9f9;
}

.btn-white i,
.btn-white-border i {
  font-size: 22px;
  line-height: 22px;
  margin-right: 10px;
  vertical-align: text-top;
}

.btn-blue {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #fff;
  background-color: #1e90ff;
  border: 1px solid #1e90ff;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.27);
  letter-spacing: 1px;
  border-radius: 23px 23px;
  margin: 5px 10px 10px 0;
  padding: 18px 25px;
  display: inline-block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.btn-blue:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.5);
  color: #fff !important;
  background: #1e90ff;
}

.btn-red {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #fff;
  background-color: #b83f40;
  border: 1px solid #b83f40;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.27);
  letter-spacing: 1px;
  border-radius: 23px 23px;
  margin: 5px 10px 10px 0;
  padding: 18px 25px;
  display: inline-block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.btn-red:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.5);
  color: #fff !important;
  background: #b83f40;
}

.btn-blue.small,
.btn-blue-line.small,
.btn-red.small,
.btn-red-line.small,
.btn-white.small,
.btn-white-border.small {
  font-size: 16px;
  padding: 14px 20px;
}

.btn-red-line {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #b83f40;
  background-color: #fff;
  border: 1px solid #b83f40;
  border-radius: 23px 23px;
  margin: 15px 10px 0 0;
  padding: 20px 30px;
  display: inline-block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.btn-red-line:hover {
  color: #fff !important;
  background: #b83f40;
}

a.btn-red:visited,
a.btn-red:focus {
  color: #fff !important;
}

/* b) Testimonials - Accordion Tabs */
.card {
  position: relative;
  margin: 20px 0;
  border: none;
  background-color: #fff !important;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}

.card-header {
  max-width: 100%;
  position: relative;
  padding: 15px 25px;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
  border-left: 6px solid #27b36a;
}

.card-header h5 .btn-link p {
  font-family: "Nunito", sans-serif;
  margin-top: 15px;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  color: #071e55;
  text-align: left !important;
  background-color: transparent;
}

.card-header h5 .btn-link p span {
  font-size: 17px;
  color: #27b36a;
}

.card-header h5 .btn-link:hover,
.card-header h5 .btn-link:focus {
  text-decoration: none;
}

.card-body {
  padding: 20px 25px 5px 25px;
  border-top: none;
  border-left: 6px solid #27b36a;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: #7c8595;
  font-style: italic;
}

img.testim-img {
  float: left;
  margin-right: 15px;
  max-width: 65px;
  border-radius: 50%;
}

p.testim-name {
  display: inline-block;
}

.testim-rating {
  margin-bottom: 30px;
}

.testim-rating i {
  color: #fdcc28;
}

.testim-platform.first {
  display: block;
  border-right: 1px solid #cecece;
}

.testim-platform p {
  margin-bottom: 5px;
  font-weight: bold;
  color: #071e55;
}

/* c) Benefits List */
ul.benefits {
  margin-bottom: 20px;
}

ul.benefits li {
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 8px;
}

ul.benefits.white li {
  color: #fff;
}

ul.benefits li i {
  font-size: 18px;
  line-height: 18px;
  margin-right: 1px;
  color: #e52a3d;
}

ul.benefits.white li i {
  color: #fff;
}

.no-padding-bottom {
  padding-bottom: 0;
}

/* ----- d) Fun Facts ----- */
.fun-facts-boxs {
  display: inline-block;
  width: 19%;
  padding: 10px 10px;
}

.fun-facts-title {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.fun-facts-title span {
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  font-style: normal;
  color: #fff;
}

.fun-facts-box i {
  font-size: 45px;
  line-height: 60px;
  color: #fff;
}

/* ----- e) Dropcaps Features ----- */
.nav-pills .nav-link {
  margin-bottom: 10px;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border: none !important;
  transition: all 0.5s;
}

.nav-pills .nav-link:hover {
  color: #fff;
  background-color: #ffffff;
  border-bottom: 1px solid #b83f40 !important;
  border-radius: 4px !important;
  box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.1) !important;
  transition: all 0.5s;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ffffff;
  border-bottom: 1px solid #b83f40 !important;
  border-radius: 4px !important;
  box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.1) !important;
  transition: all 0.5s;
}

.features-second {
  margin: 10px 0;
}

.features-second h4 {
  margin-top: 10px;
  font-size: 21px;
}

.features-second p {
  margin-bottom: 10px;
}

.dropcaps-circle {
  float: left;
  margin: 1px 25px 10px 0;
  /* background-color: #fcfcfc; */
  /* border: 1px solid #b83f40; */
  width: 80px;
  height: 80px;
  text-align: center;
  vertical-align: middle;
  color: #017df7;
  font-size: 40px;
  line-height: 80px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.dropcaps-circle.blue {
  color: #1e90ff;
}

.dropcaps-circle.red {
  color: #b83f40;
}

.dropcaps-circle.green {
  color: #27b36a;
}
.dropcaps-circle.shoot-blue {
  color: #002a5c;
}

.nav-bar-height {
  height: 100px;
}
#home h1 {
  margin-bottom: 20px;
  line-height: 1.2;
  color: #fff !important;
  text-shadow: 1px 1px #373737;
  font-size: 3rem !important;
}
.members-initiative-list-head {
  font-size: 1.5rem !important;
  font-weight: 600;
}
.members-intiative-list {
  font-size: 18px;
  color: #4d4d4d;
  font-weight: 400;
}

.members-initiative-list-li {
  margin: 14px 0px !important;
}
.mem-ben-list-bg {
  background-color: #f1f1f1;
}
.mem-ben-icon-p {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10px;
  padding-right: 18px;
}
.iframe-border {
  border: 2px solid black;
}

.footer-font-size p {
  font-size: 12px;
}

.menu-font {
  color: #071e55;
  font-size: 17px !important;
  line-height: 30px !important;
  padding: 10px 15px !important;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}



/* ========================================================== */
/* 			             11. MEDIA QUERIES                    */
/* ========================================================== */

@media (max-width: 991px) {
  .navbar-fixed-top.opaque {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-fixed-top .navbar-nav > li.discover-link {
    margin-top: 10px;
  }

  .navbar-fixed-top .navbar-nav > li > a.discover-btn {
    padding: 8px 12px !important;
    margin-left: 20px;
    font-size: 17px !important;
  }

  .home-section {
    padding-top: 170px;
    padding-bottom: 70px;
    background-attachment: inherit;
  }

  #home.home-section h1 {
    font-size: 28px !important;
    line-height: 44px;
  }
    .navbar-collapse.collapse.in {
    display: block;
}
  .navbar-collapse.collapse {
        background: #fff;
  }
  .navbar-toggler-icon{
    width: 2.3em;
  }
  .registernowlink a.discover-btn{
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .navbar-fixed-top {
    background-color: #fff;
  }

  .home-section {
    padding-top: 65px;
    padding-bottom: 90px;
    background-attachment: inherit;
  }

  .home-section-overlay {
    opacity: 0.37;
  }

  #newsletter-form #email_newsletter {
    width: 80%;
    max-width: 320px;
  }

  .footer {
    padding-top: 40px;
    text-align: center;
  }

  .footer-top i {
    display: block;
    margin-top: 50px !important;
  }

  ul.footer_social {
    margin-top: 15px;
    float: none;
  }

  ul.footer_social li a i {
    margin: 0 8px !important;
  }

  .partners-section {
    padding: 40px 0;
  }

  .partners {
    text-align: center;
  }

  .partners-title {
    font-size: 18px;
    line-height: 30px;
    float: none !important;
    margin-right: 30px;
  }

  .testim-inner {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .top-margins-images {
    margin-top: 50px;
  }

  .bottom-margins-images {
    margin-bottom: 50px;
  }

  .section-bg-1,
  .section-bg-2,
  .section-bg-3 {
    background-attachment: inherit;
  }
.navbar-collapse.collapse.in ul.navbar-nav li {
    margin-bottom: 10px;
}
}


@media  (max-width: 640px) {
    .registernowlink a.discover-btn{
    max-width: 160px;
    font-size: 16px;
    letter-spacing: 1.2px;
    }
    p, ul li{
      font-size:14px !important;
      text-align: center;
    }
  .mob-center {
    text-align: center;
}
section.section-white {
    padding: 0 0 50px 0 !important;
}
section.section-white.aboutus-section {
    padding-top: 25px !important;
}

.youtube-embed {
  position: relative;
  padding-bottom: 56%;
  width: 100%;
  text-align: left;
}

.youtube-embed iframe {
  width: 100%;
  position: absolute;
  height: 100%;
  overflow: hidden;
}
}

@media (max-width: 375px) {
  .navbar-toggle {
    border: 1px solid #9f9f9f !important;
  }

  .home-section {
    padding-top: 65px;
    padding-bottom: 70px;
  }

  .home-section h1 {
    font-size: 38px;
    line-height: 44px;
  }

  #newsletter-form #email_newsletter {
    width: 62%;
    max-width: 215px;
    padding: 0 7px;
    margin: 0 5px 20px 0;
  }

  #newsletter-form #submit-button-newsletter {
    display: inline-block;
    width: 125px;
  }

  #newsletter-section .padding-top-60 {
    padding-top: 0 !important;
  }

  #newsletter-section h3 {
    font-size: 24px !important;
  }

  .card {
    box-shadow: none;
  }

  .card-header h5 .btn-link {
    font-size: 16px;
    clear: left;
  }

  .card-header h5 .btn-link p {
    margin-top: 7px;
  }

  .card-header h5 .btn-link p span {
    display: block;
    font-size: 15px;
  }

  .section-white {
    padding: 60px 0 70px 0 !important;
  }

  .section-white.small-padding-bottom,
  .section-grey.small-padding-bottom {
    padding: 60px 0 50px 0 !important;
  }

  .margin-left-20 {
    margin-left: 1px !important;
  }

  .services-wrapper {
    margin-top: 30px;
  }

  .small-col-inside {
    margin: 50px 0;
    padding-right: 25px;
    padding-left: 25px;
  }

  .partners img.partners {
    margin: 10px 25px 10px 0;
    max-height: 40px;
  }

  .feature-box {
    text-align: center;
  }

  .feature-box i {
    display: inline-block;
    float: none;
    margin-bottom: 25px;
  }

  .feature-box-text {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 320px) {
  .newsletter-input {
    width: 60%;
  }
  .btn-newsletter {
    width: 38%;
    height: 60px;
    margin-top: 12px;
  }
}


</style>
