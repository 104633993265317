<template>
  <div id="sidebar_nav" class="" :class="mob_MenuOpen ? 'mob_menu_show' : ''">
    <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
      <div class="menu_icon_bar">
        <div class="mob_menu_header">
          <a href="javascript:void(0)" class="mob_menu_back" @click="closeMenuAll">Back</a>
        </div>
        <div class="menu_scroller">
          <div class="sidebarloader" v-if="sidebarLoader">
            <sidebarLoader />
          </div>
          <ul v-else>
            <template>
              <li v-for="(mitem, index) in primaryItems" :key="index" class="menu-item admin level_1"
                  :class="{ 'active': activeNav == mitem.id, 'visible_li': mitem.status, 'disablelist': !mitem.status }"
                  v-bind:title="mitem.status === 0 ? 'Coming Soon' : ''" :id="mitem.id">
                <a href="javascript:void(0)"
                   v-on:click="mobLink(mitem.url, mitem.id, mitem.status)" :data-id="mitem.id"
                   :key="index"><i class="menu-icon" v-bind:class="mitem.icon"></i>
                  <span class="full_label">{{ mitem.description }}</span>
                  <span class="short_label text-capitalize" v-bind:class="mitem.class">{{ mitem.name }}</span>
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div id="product_menus" class="product_menus sidebar sidebarscorll" v-bind:style="menuOpen ? '' : ''" :class="classCtrl"
           v-show="menuOpen" :key="menuOpen">
        <div id="mms_menu" class="menu_list showmms">
          <perfect-scrollbar>
            <div class='menu_list_items'>
              <div class="mob_menu_header">
                <a href="javascript:void(0)" class="mob_menu_back" @click="secondMenuClose">Back</a>
                <!-- <h3><i class="menu_header-icon" v-bind:class="'head_' + mob_label.icon"></i>{{
                    mob_label.full_name }}
                </h3> -->
              </div>
              <ul>
                <li v-for="(module, menuIndex) in  menuItems"  :key="menuIndex" v-bind:class="isRouteActive(module.child, module.href)">
                  <template v-if="module.external">
                    <a :href="module.href" :target="module.target || '_self'">{{ module.title }}</a>
                  </template>
                  <router-link v-else :to="routerOther(module.href)" class="vueroute">
                    {{ module.title }}
                  </router-link>
                  <ul v-if="module.child">
                    <li v-for="( childPage, childIndex ) in  module.child "
                        :key="childIndex" :class=" childPage.child ? 'has-sub' :''">
                      <template v-if="childPage.external">
                        <a :href="childPage.href" :target="childPage.target || '_self'">{{ childPage.title }}</a>
                      </template>
                      <router-link v-else :to="childPage.href" >
                        {{ childPage.title }}
                      </router-link>
                      <ul v-if=" childPage.child " :class=" childPage.child ? 'has-sub' :''">
                        <li v-for="( item, itemkey ) in childPage.child" :key="itemkey">
                          <router-link :to="item.href" >
                            {{ item.title }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </perfect-scrollbar>

        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import $ from 'jquery';
import menujson from '../../../assets/menu.json';
import { EventBus } from '@/event-bus';
import axios from "axios";
// import { SidebarMenu } from "vue-sidebar-menu";
// import sidebarlogo from "./SidebarLogo.vue";
import sidebarLoader from './sidebarLoader.vue';
export default {
  name: "Sidebar",
  components: {
    // SidebarMenu,
    // sidebarlogo,
    sidebarLoader
  },


  data() {
    return {
      menuOpen: true,
      mob_MenuOpen: false,
      sec_menuClass: false,
      mob_label: {},
      activeNav: "1",
      adminInfoData: "",
      role: 0,
      menu1: [
        {
          href: "/admin/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          title: "Registrations",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/registrations",
              title: "Individual",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/family-registrations",
              title: "Family",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/club-registrations",
              title: "Club",
              icon: "far fa-circle",
            },
            {
              href: "/admin/ambassador-registrations",
              title: "Ambassador",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations",
              title: "Camp/Ski School",
              icon: "far fa-circle",
            },
          ],
        },
        {
          href: process.env.VUE_APP_WATERSKI_EMS_URL,
          external: true,
          title: "Events & Clinics",
          icon: "fas fa-child ",
        },
        {
          href: process.env.VUE_APP_WATERSKI_LMS_URL,
          external: true,
          title: "Courses",
          icon: "fas fa-clipboard",
        },
        {
          href: "/admin/merchandise",
          title: "Merchandise",
          icon: "fas fa-dolly",
        },
        {
          title: "Groups",
          icon: "fas fa-users",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/group-types",
              title: "Group Types",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups",
              title: "Groups",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-position",
              title: "Positions",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-member",
              title: "Members",
              icon: "fas fa-users nav-icon",
            }
          ]
        },
        {
          title: "Reports",
          icon: "fas fa-chart-pie",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/reports/member-registrations",
              title: "Membership Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/club/reports",
              title: "Club Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/reports/bgscreening",
              title: "BG Screening Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/mvr",
              title: "MVR Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations/reports",
              title: "Camp/Ski School Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/financials",
              title: "Financials Report",
              icon: "far fa-circle",
            },
            // {
            //   href: "/admin/financials-membership",
            //   title: "Membership Financials Reports",
            //   icon: "far fa-circle",
            // },
            // {
            //   href: "/admin/financials-club",
            //   title: "Club Financials Reports",
            //   icon: "far fa-circle",
            // },
            {
              href: "/admin/reports/donation",
              title: "Donations",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/club_insurance",
              title: "Club Insurance",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/insurance_certificate",
              title: "Insurance Certificate",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/merchandise-accounting-report",
              title: "Merchandise Accounting",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/merchandise-fulfillment-report",
              title: "Merchandise Fulfillment",
              icon: "far fa-circle",
            },
            {
              href: "/admin/report/summary-report",
              title: "Summary Reports",
              icon: "far fa-circle",
            },
          ],
        }
      ],
      menu2: [
        {
          href: "/admin/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          title: "Registrations",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/registrations",
              title: "Individual",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/family-registrations",
              title: "Family",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/club-registrations",
              title: "Club",
              icon: "far fa-circle",
            },
            {
              href: "/admin/ambassador-registrations",
              title: "Ambassador",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations",
              title: "Camp/Ski School",
              icon: "far fa-circle",
            },
          ],
        },
        {
          title: "Groups",
          icon: "fas fa-users",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/group-types",
              title: "Group Types",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups",
              title: "Groups",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-position",
              title: "Positions",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-member",
              title: "Members",
              icon: "fas fa-users nav-icon",
            }
          ]
        },
        {
          title: "Reports",
          icon: "fas fa-chart-pie",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/reports/member-registrations",
              title: "Membership Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/club/reports",
              title: "Club Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/reports/financials",
              title: "Financials Report",
              icon: "far fa-circle",
            },
            // {
            //   href: "/admin/financials-membership",
            //   title: "Membership Financials Reports",
            //   icon: "far fa-circle",
            // },
            // {
            //   href: "/admin/financials-club",
            //   title: "Club Financials Reports",
            //   icon: "far fa-circle",
            // },
            {
              href: "/admin/reports/donation",
              title: "Donations",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/club_insurance",
              title: "Club Insurance",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/insurance_certificate",
              title: "Insurance Certificate",
              icon: "far fa-circle",
            },
            {
              href: "/admin/report/summary-report",
              title: "Summary Reports",
              icon: "far fa-circle",
            },
        {
          href: process.env.VUE_APP_WATERSKI_LMS_URL+ "login/index.php",
          external: true,
          title: "",
          class: "lms_logo",
          badge: {
            text: "lms",
            class: "",
            attributes: { src: "/dist/img/lms_button1.png" },
            element: "img",
          },
        },
          ],
        }
      ],
      collapsed: false,
      menu: [
        {
          href: "/admin/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
          meta: "forAuth",
        },
        {
          href:"",
          title: "Registrations",
          icon: "fas fa-address-card",
          class: "registrations-list-menu",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/registrations",
              title: "Individual",
              icon: "far fa-circle ",
              class: "individual-registrations-menu",
            },
            {
              href: "/admin/family-registrations",
              title: "Family",
              icon: "far fa-circle ",
              class: "family-registrations-menu",
            },
            {
              href: "/admin/club-registrations",
              title: "Club",
              icon: "far fa-circle",
              class: "club-registrations-menu",
            },
            {
              href: "/admin/ambassador-registrations",
              title: "Ambassador",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations",
              title: "Camp/Ski School",
              icon: "far fa-circle",
              class: "campski-registrations-menu",
            },
            {
              href: "/admin/digital-registrations",
              title: "Digital Membership",
              icon: "far fa-circle",
            }

          ],
        },
        // {
        //   href: "#",
        //   title: "Members",
        //   icon: "fas fa-address-card",
        //   child: [
        //     {
        //       href: "#",
        //       title: "External Links",
        //       icon: "far fa-circle ",
        //     },
        //     {
        //       href: "#",
        //       title: "Registration",
        //       icon: "far fa-circle ",
        //     },
        //     {
        //       href: "#",
        //       title: "Waiver",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "#",
        //       title: "Insurance",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "#",
        //       title: "SafeSport",
        //       icon: "far fa-circle",
        //     },
        //   ],
        // },
        {
          href:"",
          title: "Reports",
          icon: "fas fa-chart-pie",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/reports/member-registrations",
              title: "Membership Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/club/reports",
              title: "Club Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/reports/bgscreening",
              title: "BG Screening Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/mvr",
              title: "MVR Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations/reports",
              title: "Camp/Ski School Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/financials",
              title: "Financials Report",
              icon: "far fa-circle",
            },
            // {
            //   href: "/admin/financials-membership",
            //   title: "Membership Financials Reports",
            //   icon: "far fa-circle",
            // },
            // {
            //   href: "/admin/financials-club",
            //   title: "Club Financials Reports",
            //   icon: "far fa-circle",
            // },
            {
              href: "/admin/reports/donation",
              title: "Donations",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/club_insurance",
              title: "Club Insurance",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/insurance_certificate",
              title: "Insurance Certificate",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/merchandise-accounting-report",
              title: "Merchandise Accounting",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/merchandise-fulfillment-report",
              title: "Merchandise Fulfillment",
              icon: "far fa-circle",
            },
            {
              href: "/admin/report/summary-report",
              title: "Summary Reports",
              icon: "far fa-circle",
            },
          ],
        },
        {
          href: process.env.VUE_APP_WATERSKI_EMS_URL,
          external: true,
          title: "Events & Clinics",
          icon: "fas fa-child ",
        },
        {
          href: process.env.VUE_APP_WATERSKI_LMS_URL + "login/index.php",
          external: true,
          title: "Courses",
          icon: "fas fa-clipboard",
          target: "_blank"
        },
        {
          href: "/admin/merchandise",
          title: "Merchandise",
          icon: "fas fa-dolly",
        },
        {
          href: "/admin/coupons",
          title: "Coupons",
          icon: "fas fa-money-bill nav-icon",
        },
        {
          href: "/admin/member-type",
          title: "Member Types",
          icon: "fas fa-address-card",
        },
        {
          href:"",
          title: "Pricing",
          icon: "fas fa-dollar-sign",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/membership-pricing",
              title: "Membership Pricing",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/other-pricing",
              title: "Other Pricing",
              icon: "fas fa-users nav-icon",
            }
          ]
        },
        {
          href:"",
          title: "Groups",
          icon: "fas fa-users",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/group-types",
              title: "Group Types",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups",
              title: "Groups",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-position",
              title: "Positions",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-member",
              title: "Members",
              icon: "fas fa-users nav-icon",
            }
          ]
        },
        {
          href: "/admin/seasons",
          hidden: true,
          title: "Seasons",
          icon: "fas fa-calendar",
        },
        // {
        //   href: "#",
        //   title: "Settings",
        //   icon: "fas fa-tools",
        //   hidden: this.role == 1,
        //   child: [
        //     {
        //       href: "/",
        //       title: "CMS",
        //       icon: "far fa-circle ",
        //     },
        //     {
        //       href: "/",
        //       title: "Coupons",
        //       icon: "far fa-circle ",
        //     },
        //     {
        //       href: "/",
        //       title: "Donations",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "/",
        //       title: "Merchandize",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "/",
        //       title: "Fees",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "/",
        //       title: "Others",
        //       icon: "far fa-circle",
        //     },
        //   ],
        // },
        {
          href:"",
          title: "Security",
          icon: "fas fa-key",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/admin-user-list",
              title: "Manage users",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/admin-user-role",
              title: "Manage Roles",
              icon: "far fa-circle ",
            },
            // {
            //   href: "/",
            //   title: "Manage Features",
            //   icon: "far fa-circle",
            // },
          ],
        },
        {
          href: "/admin/registration/settings",
          title: "Settings",
          icon: "fas fa-cogs",
        },
        {
          href:"",
          title: "Consolidate",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/mergeDelete",
              title: "Members Consolidate",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/clubMergeDelete",
              title: "Clubs Consolidate",
              icon: "far fa-circle ",
            },

            // {
            //   href: "/",
            //   title: "Manage Features",
            //   icon: "far fa-circle",
            // },
          ],
        },
        {
          href:"",
          title: "Campaign Manager",
          icon: "fas fa-envelope",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/template-types",
              title: "Template Types",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/email-templates",
              title: "Email Templates",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/create-campaign",
              title: "Create Campaign",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/campaign-history-reports",
              title: "History & Reports",
              icon: "far fa-circle ",
            },
          ],
        },
        {
          href: "",
          title: "Content Management",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "",
              title: "FAQ",
              icon: "far fa-circle ",
              hiddenOnCollapse: true,
              child: [
                {
                  href: "/admin/faq-sections",
                  title: "Section",
                  icon: "far fa-circle ",
                },
                {
                  href: "/admin/faq-list",
                  title: "FAQ List",
                  icon: "far fa-circle",
                },
              ]
            },
            {
              href: "/admin/member-dashboard",
              title: "Member Dashboard",
              icon: "far fa-circle "
            },
            {
              href: "/admin/landingpage",
              title: "Landing Page",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/resource",
              title: "Resources",
              icon: "far fa-circle "
            },
            {
              href: "/admin/certificate-of-insurances",
              title: "Certificate of Insurances",
              icon: "far fa-circle "
            },
            {
              href: "/admin/magazine",
              title: "Magazines",
              icon: "far fa-circle "
            },
          ],
        },

        {
          href: process.env.VUE_APP_WATERSKI_EMS_URL,
          external: true,
          title: "",
          class: "ems_logo",
          badge: {
            text: "lms",
            class: "",
            attributes: { src: "/dist/img/ems_button1.png" },
            element: "img",
          },
        },
        {
          href: process.env.VUE_APP_WATERSKI_LMS_URL+ "login/index.php",
          external: true,
          title: "",
          class: "lms_logo",
          badge: {
            text: "lms",
            class: "",
            attributes: { src: "/dist/img/lms_button1.png" },
            element: "img",
          },
        },
        // {
        //   href: "",
        //   title: "Download App",
        //   icon: "fas fa-download",
        //   hidden: this.role == 1,
        // },
        // {
        //   href: "http://usawseventreg.integrass.com/",
        //   external: true,
        //   title: "",
        //   class: "appstore_logo",
        //   badge: {
        //     text: "lms",
        //     class: "",
        //     attributes: { src: "/dist/img/mac_store.png" },
        //     element: "img",
        //   },
        // },
        // {
        //   href: "http://usawseventreg.integrass.com/",
        //   external: true,
        //   title: "",
        //   badge: {
        //     text: "lms",
        //     class: "",
        //     attributes: { src: "/dist/img/app_store.png" },
        //     element: "img",
        //   },
        // },
      ],
      shooting: {
        admin: [
          {
            href: "/admin/dashboard",
            title: "Dashboard",
            icon: "fas fa-tachometer-alt",
            meta: "forAuth",
          },
          {
            href:"",
            title: "Registrations",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/registrations",
                title: "Individual",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/family-registrations",
                title: "Family",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/club-registrations",
                title: "Club",
                icon: "far fa-circle",
              },

              {
                href: "/admin/coach-registrations",
                title: "Coach",
                icon: "far fa-circle",
              },
              {
                href: "/admin/fan-registrations",
                title: "Fan",
                icon: "far fa-circle",
              }
            ],
          },
          {
            href: "/admin/coupons",
            title: "Coupons",
            icon: "fas fa-money-bill nav-icon",
          },
          {
            href:"",
            title: "Pricing",
            icon: "fas fa-dollar-sign",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/membership-pricing",
                title: "Membership Pricing",
                icon: "fas fa-users nav-icon",
              },
              {
                href: "/admin/other-pricing",
                title: "Other Pricing",
                icon: "fas fa-users nav-icon",
              }
            ]
          },
          {
            href:"",
            title: "Reports",
            icon: "fas fa-chart-pie",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/reports/member-registrations",
                title: "Membership Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/club/reports",
                title: "Club Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/reports/bgscreening",
                title: "BG Screening Reports",
                icon: "far fa-circle",
              },
              {
                href: "/admin/reports/donation",
                title: "Donations",
                icon: "far fa-circle",
              },
              {
                href: "/admin/reports/financials",
                title: "Financials Report",
                icon: "far fa-circle",
              },
              {
                href: "/admin/reports/summary",
                title: "Summary Reports",
                icon: "far fa-circle",
              },
            ],
          },
          {
            href:"",
            title: "Security",
            icon: "fas fa-key",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/admin-user-list",
                title: "Manage users",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/admin-user-role",
                title: "Manage Roles",
                icon: "far fa-circle ",
              }
            ],
          },
          {
            href: "/admin/registration/settings",
            title: "Settings",
            icon: "fas fa-cogs",
          },
          {
            href:"",
            title: "Imports",
            icon: "fas fa-upload",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/imports/background-screening",
                title: "Background Screening",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/imports/safe-sport",
                title: "Safe Sport",
                icon: "far fa-circle ",
              }
            ],
          },
          {
            href: "/admin/group",
            title:"Groups",
            icon: "fas fa-object-group",
          },
          {
            href:"",
            title: "Consolidate",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/mergeDelete",
                title: "Members Consolidate",
                icon: "far fa-circle ",
              },
              // {
              //   href: "/admin/clubMergeDelete",
              //   title: "Clubs Consolidate",
              //   icon: "far fa-circle ",
              // },

              // {
              //   href: "/",
              //   title: "Manage Features",
              //   icon: "far fa-circle",
              // },
            ],
          },
          {
            href: process.env.VUE_APP_SHOOTING_PFM_SSO_URL+"?email="+localStorage.getItem("pfm_sso_token"),
            title:"Campaign Manager",
            icon: "fas fa-envelope",
            external: true,
            target: "_blank"
          },
          {
            href:"",
            title: "Content Management",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "",
                title: "FAQ",
                icon: "far fa-circle ",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "/admin/faq-sections",
                    title: "Section",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/faq-list",
                    title: "FAQ List",
                    icon: "far fa-circle",
                  },
                ]
              },
              {
                href: "/admin/member-dashboard",
                title: "Member Dashboard",
                icon: "far fa-circle "
              },
              {
                href: "/admin/landingpage",
                title: "Landing Page",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/resource",
                title: "Resources",
                icon: "far fa-circle "
              },
            ],
          },
          {
            href: process.env.VUE_APP_SHOOTING_EMS_URL,
            external: true,
            title: "",
            class: "ems_logo",
            badge: {
              text: "ems",
              class: "",
              attributes: { src: "/dist/img/ems_button1.png" },
              element: "img",
            },
          },
          {
            href: process.env.VUE_APP_SHOOTING_LMS_URL+ "login/index.php",
            external: true,
            title: "",
            class: "lms_logo",
            badge: {
              text: "lms",
              class: "",
              attributes: { src: "/dist/img/lms_button1.png" },
              element: "img",
            },
          },
        ],
        manager: [
          {
            href: "/admin/dashboard",
            title: "Dashboard",
            icon: "fas fa-tachometer-alt",
          },
          {
            title: "Registrations",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/registrations",
                title: "Individual",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/family-registrations",
                title: "Family",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/club-registrations",
                title: "Club",
                icon: "far fa-circle",
              },
            ],
          },
          {
            href:"",
            title: "Reports",
            icon: "fas fa-chart-pie",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/reports/member-registrations",
                title: "Membership Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/club/reports",
                title: "Club Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/reports/donation",
                title: "Donations",
                icon: "far fa-circle",
              },
              // {
              //   href: "/admin/reports/financials",
              //   title: "Financials Report",
              //   icon: "far fa-circle",
              // },
              // {
              //   href: "/admin/report/summary-report",
              //   title: "Summary Reports",
              //   icon: "far fa-circle",
              // },

            ],
          },
          {
            href:"",
            title: "Content Management",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "#",
                title: "FAQ",
                icon: "far fa-circle ",
                hiddenOnCollapse: true,
                subChild: [
                  {
                    href: "/admin/faq-sections",
                    title: "Section",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/faq-list",
                    title: "FAQ List",
                    icon: "far fa-circle",
                  },
                ]
              },
              {
                href: "/admin/member-dashboard",
                title: "Member Dashboard",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/landingpage",
                title: "Landing Page",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/resource",
                title: "Resources",
                icon: "far fa-circle "
              },
            ],
          },
          // {
          //   title: "Security",
          //   icon: "fas fa-key",
          //   hiddenOnCollapse: true,
          //   child: [
          //     {
          //       href: "/admin/admin-user-list",
          //       title: "Manage users",
          //       icon: "far fa-circle ",
          //     },
          //     {
          //       href: "/admin/admin-user-role",
          //       title: "Manage Roles",
          //       icon: "far fa-circle ",
          //     }
          //   ],
          // },
          {
            href: process.env.VUE_APP_SHOOTING_LMS_URL+ "login/index.php",
            external: true,
            title: "",
            class: "lms_logo",
            badge: {
              text: "lms",
              class: "",
              attributes: { src: "/dist/img/lms_button1.png" },
              element: "img",
            },
          },
        ],
      },
      alliancefastpitch:
          {
            admin: [
              {

                href: "/admin/dashboard",
                title: "Dashboard",
                icon: "fas fa-tachometer-alt",
                meta: "forAuth",
              },
              {
                href: "",
                title: "Registrations",
                icon: "fas fa-address-card",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "/admin/registrations",
                    title: "Player",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/staff-registrations",
                    title: "Staff",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/team-registrations",
                    title: "Team",
                    icon: "far fa-circle",
                  },
                ],
              },
              {
                href: "",
                title: "Free Agent",
                icon: "fas fa-street-view",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "/admin/free-agent",
                    title: "Player",
                    icon: "far fa-circle",
                  },
                  {
                    href: "/admin/staff-free-agent",
                    title: "Coach",
                    icon: "far fa-circle",
                  },
                ],
              },
              {
                href: "/admin/coupons",
                title: "Coupons",
                icon: "fas fa-money-bill nav-icon",
              },
              {
                href: "/admin/membership-pricing",
                title: "Membership Pricing",
                icon: "fas fa-dollar-sign nav-icon",
              },
              {
                href: "",
                title: "Reports",
                icon: "fas fa-chart-pie",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "/admin/reports/registration",
                    title: "Membership Reports",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/team/reports",
                    title: "Team Reports",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/reports/donation",
                    title: "Donations",
                    icon: "far fa-circle",
                  },
                  {
                    href: "/admin/reports/financials",
                    title: "Financials Report",
                    icon: "far fa-circle",
                  },
                  {
                    href: "/admin/reports/summary",
                    title: "Summary Reports",
                    icon: "far fa-circle",
                  },
                  // {
                  //   href: "/admin/recruiting-member/reports",
                  //   title: "Recruiting Player Reports",
                  //   icon: "far fa-circle ",
                  // },
                  {
                    href: "/admin/Recruiting-team/reports",
                    title: "Recruiting Team Reports",
                    icon: "far fa-circle ",
                  },
                ],
              },
              {
                href: "",
                title: "Security",
                icon: "fas fa-key",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "/admin/admin-user-list",
                    title: "Manage users",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/admin-user-role",
                    title: "Manage Roles",
                    icon: "far fa-circle ",
                  }
                ],
              },
              {
                href: "/admin/registration/settings",
                title: "Settings",
                icon: "fas fa-cogs",
              },
              {
                href: "",
                title: "Player Transfer",
                icon: "fas fa-share nav-icon",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "/admin/playerTransfer/request",
                    title: "Player Transfer Request",
                    icon: "far fa-circle",
                  },
                  {
                    href: "/admin/playerTransfer/enable",
                    title: "Player Transfer Enable",
                    icon: "far fa-circle",
                  },
                  {
                    href: "/admin/playerTransfer/history",
                    title: "Player Transfer History",
                    icon: "far fa-circle",
                  },
                ],
              },
              {
                href: "",
                title: "Campaign Manager",
                icon: "fas fa-envelope",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "/admin/template-types",
                    title: "Template Types",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/email-templates",
                    title: "Email Templates",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/create-campaign",
                    title: "Create Campaign",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/campaign-history-reports",
                    title: "History & Reports",
                    icon: "far fa-circle ",
                  },
                ],
              },
              {
                href: "",
                title: "Content Management",
                icon: "fas fa-address-card",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "",
                    title: "FAQ",
                    icon: "far fa-circle ",
                    hiddenOnCollapse: true,
                    child: [
                      {
                        href: "/admin/faq-sections",
                        title: "Section",
                        icon: "far fa-circle ",
                      },
                      {
                        href: "/admin/faq-list",
                        title: "FAQ List",
                        icon: "far fa-circle",
                      },
                    ]
                  },
                  {
                    href: "/admin/member-dashboard",
                    title: "Member Dashboard",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/landingpage",
                    title: "Landing Page",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/resource",
                    title: "Resources",
                    icon: "far fa-circle "
                  },
                ],
              },
            ],
            manager: [
              {
                href: "/admin/dashboard",
                title: "Dashboard",
                icon: "fas fa-tachometer-alt",
              },
              {
                href: "",
                title: "Registrations",
                icon: "fas fa-address-card",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "/admin/registrations",
                    title: "Player",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/staff-registrations",
                    title: "Staff",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/team-registrations",
                    title: "Team",
                    icon: "far fa-circle",
                  },
                ],
              },
              {
                href: "",
                title: "Reports",
                icon: "fas fa-chart-pie",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "/admin/reports/registration",
                    title: "Membership Reports",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/team/reports",
                    title: "Team Reports",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/reports/donation",
                    title: "Donations",
                    icon: "far fa-circle",
                  },
                ],
              },
              {
                href: "",
                title: "Content Management",
                icon: "fas fa-address-card",
                hiddenOnCollapse: true,
                child: [
                  {
                    href: "",
                    title: "FAQ",
                    icon: "far fa-circle ",
                    child: [
                      {
                        href: "/admin/faq-sections",
                        title: "Section",
                        icon: "far fa-circle ",
                      },
                      {
                        href: "/admin/faq-list",
                        title: "FAQ List",
                        icon: "far fa-circle",
                      },
                    ]
                  },
                  {
                    href: "/admin/member-dashboard",
                    title: "Member Dashboard",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/landingpage",
                    title: "Landing Page",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/resource",
                    title: "Resources",
                    icon: "far fa-circle "
                  },
                ],
              },
            ],
          },
      subChildStatus:false,
      menuItems:[],
      primaryItems:[],
      menuItems1 : menujson['iconbar'],
      sidebarLoader: true,
    };
  },

  computed: {
    classCtrl() {
      console.log('classCtrl',this.mob_MenuOpen );
      if (this.mob_MenuOpen) {
        $('body').addClass('oveflowhidden');

        $('#product_menus').animate({ "left": "0px" }, "slow");

        return {
          'menu_items_show mob_items_visible': this.menuOpen,
        };
      } else {
        $('body').removeClass('oveflowhidden');

        return {
          'menu_items_show': this.menuOpen,
        }
      }
    }
  },
  created() {
    EventBus.$on('showmenuEvent', (evt) => {
      this.menuOpen = evt;
    });
    EventBus.$on('showmenuMob', (evt) => {
      this.mob_MenuOpen = evt;
    });
  },
  watch: {
    $route(to, from) {
      console.log("to, from", to.path !== '#' && to.path !== from.path);
      if (to.path !== '#' && to.path !== from.path) {
        if (this.mob_MenuOpen) {
          this.mob_MenuOpen = false;
          EventBus.$emit('mobTogglerClose', false)
          $('body').removeClass('oveflowhidden');
        }
      } else {
        /* Dropdown menu clicking */
      }
    }
  },
  methods: {
    getPrimaryMenu(){
      axios.get(this.basePath + "api/mms/get-module-menu-config", { headers: this.adminHeaders })
          .then((response) => {
            this.primaryItems = response.data.data;
            this.sidebarLoader = false;
          })
          .catch(error => {
            console.log("error")
          })
    },
    mobLink(link,id, status){
      if (id == 1){
        this.activeMenu(id);
      }
      else if(status === 0){
        window.location.href = 'javascript:void(0)'
      }
      else if(id === 3){
        window.open(link, '_blank');
      }
      else if (id === 4) {
        link = process.env.VUE_APP_SHOOTING_PFM_SSO_URL+"?email="+localStorage.getItem("pfm_sso_token")+"&fundraiser=redirect";
        window.open(link, '_blank');
      }
      else{
        window.location.href = link;
      }
    },
    isRouteActive(child, route) {
      let cl = '';
      let currentRoute = this.$route.path;
      if (child) {
        const exists = child.some(childit => childit.href === currentRoute);
        if (exists) {
          cl += 'has-sub opened active';
        }
        else {
          cl += 'has-sub';
        }
      }
      if (this.$route.path === route) {
        cl += 'opened active';
      }
      return cl;
    },
    activeMenu(id) {
      this.activeNav = id;
      console.log("this.activeNav",this.activeNav);
      this.mobActiveText()
      if (this.mob_MenuOpen) {
        this.sec_menuClass = true;
      } else {
        this.sec_menuClass = false;
      }
    },
    closeMenuAll() {
      this.mob_MenuOpen = false;
      this.sec_menuClass = false;
      this.secondMenuClose();
      EventBus.$emit('mobTogglerClose', false)
      $('body').removeClass('oveflowhidden');
    },
    secondMenuClose() {
      $('.menu_icon_bar').removeClass('showMenuList');
      $('#product_menus .menu_list').removeClass('showNav');
      $('.menu_list').removeClass('opened active');
    },
    mobActiveText() {
      let labeltext = this.activeNav
      let settingsIndex = this.menuItems.findIndex(function (element) {
        return element.id === labeltext;
      });
      this.mob_label = this.menuItems[settingsIndex];
    },
    routerOther(url) {
      return url;
    },
    maintoSubToggle() {
      this.menuOpen = true;
      EventBus.$emit('deskTogglerOpen', true);
    },
    // onToggleCollapse(collapsed) {
    //   this.collapsed = collapsed;
    // },
    // onItemClick(event, item, node) {
    //   console.log("onItemClick");
    // },
    // childClose(subChild){
    //   if(this.subChildStatus == false && subChild==undefined) {
    //       $('.sub-child').css("display", "none");
    //       $('.child').removeClass("menu-open");
    //   }
    //   this.subChildStatus = false;
    // },
    // subChild(){
    //    this.subChildStatus = true;
    // }
  },

  mounted() {
    this.getPrimaryMenu();
    this.adminInfoData = JSON.parse(localStorage.getItem("admin"));
    this.role = JSON.parse(localStorage.getItem("role"));

    if(this.subdomain == "waterski" && this.role == 1) this.menuItems = this.menu;
    if(this.subdomain == "waterski" && this.role == 2) this.menuItems = this.menu1;
    if(this.subdomain == "waterski" && this.role > 2) this.menuItems = this.menu2;
    if(this.subdomain == "shooting" && this.role == 1) this.menuItems = this.shooting.admin;
    if(this.subdomain == "shooting" && this.role != 1) this.menuItems = this.shooting.manager;

    if(this.subdomain == "alliancefastpitch" && this.role == 1) this.menuItems = this.alliancefastpitch.admin;
    if(this.subdomain == "alliancefastpitch" && this.role != 1) this.menuItems = this.alliancefastpitch.manager;
    window.sidebar = this;
    this.mobActiveText()
  },
};
$(document).on('click', '.menu_icon_bar .menu-item.admin.visible_li > a', function () {
  let menu_id = $(this).data('id');
  if (menu_id != 1){
    console.log("true block");
    if (menu_id === 2) {
      return false;
    }else if(menu_id === 3){
      return false;
    }
  }else{
    console.log("false block");
    if (menu_id === 1) {
      window.sidebar.maintoSubToggle();
      $('.menu_icon_bar').addClass('showMenuList');
      $('#mms_menu').addClass('showmms');
      $('.menu_icon_bar .menu-item').removeClass('active');
      $(this).parent('li').addClass('active');

      $('#product_menus').hide();
      $('#product_menus .menu_list').removeClass('showNav');
      $('#product_menus #' + menu_id + '_menu').addClass('showNav');
      $('#product_menus').show();
      $('.has-sub').removeClass('opened active');
    }
  }
});
$(document).on('click', 'li.has-sub>a', function () {
  $(this).removeAttr('href');
  let element = $(this).parent('li');
  if (element.hasClass('opened')) {
    element.removeClass('opened active');
    element.find('li').removeClass('opened active');
    element.find('ul').slideUp();
  }
  else {
    element.addClass('opened active');
    element.children('ul').slideDown();
    element.siblings('li').children('ul').slideUp();
    element.siblings('li').removeClass('opened active');
    element.siblings('li').find('li').removeClass('opened active');
    element.siblings('li').find('ul').slideUp();
  }
});
</script>

<style>
/* .v-sidebar-menu.vsm_expanded {
  max-width: 250px !important;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background-color: transparent;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 3px 0px 0px 0px hsla(0, 0%, 100%, 0.1) inset;
  box-shadow: 3px 0px 0px 0px hsl(0deg 0% 100% / 10%) inset;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color: transparent !important;
}

.v-sidebar-menu .vsm--icon {
  background-color: transparent !important;
}
.vsm--dropdown .vsm--list {
  padding-left: 0 !important;
}
.v-sidebar-menu .vsm--dropdown .vsm--list .vsm--item a {
  padding-left: 20px !important;
} */

</style>