<template>
  <div class="freezeheader">
    <nav class="navbar navbar-expand navbar-white navbar-light p-0">
      <!-- Left navbar links -->
      <a href="javascript:void(0)" data-widget="pushmenu" class="nav-toggler deskToggler"
        :class="header ? 'menu_opened' : 'menu_closed'" @click="deskToggler">
        <div class="nav-toggle">
          <div class="icon-bar"></div>
          <div class="icon-bar"></div>
          <div class="icon-bar"></div>
        </div>
      </a>
      <a href="javascript:void(0)" class="nav-toggler mobToggler" :class="header ? 'menu_opened' : 'menu_closed'"
        @click="mobToggler">
        <div class="nav-toggle">
          <div class="icon-bar"></div>
          <div class="icon-bar"></div>
          <div class="icon-bar"></div>
        </div>
      </a>

      <div class="header-main">
        <ul class="navbar-nav">
          <li class="nav-item app-logo mr-4">
           
      <a href="#"  v-if="this.getSiteProps('member.logo') == undefined">
        <img
          :src="'/' + this.getSiteProps('login.logo')"
          v-if="'/' + this.getSiteProps('login.logo')"          
          style="opacity: 1;"
        />
        <span class="brand-text font-weight-bolder"></span>
      </a>
      <a href="#"  v-else>
        <img
          :src="'/' + this.getSiteProps('member.logo')"
          v-if="'/' + this.getSiteProps('member.logo')"          
          style="opacity: 1;"
        />
        <span class="brand-text font-weight-bolder"></span>
      </a>
    
            <!-- <img src="../../../../public/shooting/logo.png" alt="logo" /> -->
          </li>
          <!-- <li class="nav-item d-none d-sm-inline-block">   <a class="nav-link" href="/admin/dashboard">Home</a>   </li>  -->
          <li class="nav-item header-search mob-srch ml-5" v-if="getSiteProps('general.global_search')">
            <b-button class="btn-primary btn-darkblue btn mob-btn" v-b-modal.modal-general-search> <i class="fas fa-search"></i></b-button>
            <div class="navbar-search-block pt-1 mr-3 desk-search">
              <b-form @submit="globalSearch">
                <div class="input-group input-group-sm">
                  <b-form-input v-model="qs" placeholder="Search" class="form-control form-control-navbar"
                    aria-label="Search" id="globalSearch"></b-form-input>
                  <div class="input-group-append">
                    <button class="btn btn-navbar" type="submit">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </b-form>
            </div>
          </li>
        </ul>
        <b-modal id="modal-general-search" v-bind:hide-footer="true" ref="modal-general-search" size="lg"  title="Search">
          <div class="navbar-search-block pt-1 mr-3">
              <b-form @submit="globalSearch">
                <div class="input-group input-group-sm">
                  <b-form-input v-model="qs" placeholder="Search" class="form-control form-control-navbar"
                    aria-label="Search" id="globalSearch"></b-form-input>
                  <div class="input-group-append">
                    <button class="btn btn-navbar" type="submit">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </b-form>
            </div>
        </b-modal>
        <!-- Right navbar links -->
        
          <!-- <li class="nav-item" >
                <div class="user-panel pt-1 pb-1 d-flex">
                    <div class="image">
                        <img src="/dist/img/avatarimg.png" alt="User Image" class="img-circle">
                    </div>
                  <div class="info">
                        <a href="#" class="d-block text-capitalize">{{ adminInfoData }}</a>
                  </div>
               </div>
            </li>
            <li class="nav-item">
                <a class="nav-link logout" data-widget="control-sidebar" @click="logout" data-slide="true" href="#" role="button">
                <img style="width:18px;" src="/dist/img/ico-logout.svg"> Log Out
                </a> 
            </li>   -->
       
        <div class="d-flex align-items-center profile-dropdown ml-auto">
          <ul class="navbar-nav ml-auto rightnavbar">
          <li class="nav-item top-barnav w-phone-hide" v-if="subdomain == 'waterski'">
            <a class="nav-link logout" data-widget="control-sidebar" href="tel:863-324-4341">
              <img style="width:18px;" src="/dist/img/ico-phone.svg"> 863-324-4341
            </a>
          </li>
          <li class="nav-item top-barnav w-email-hide" v-if="subdomain == 'waterski'">
            <a class="nav-link logout text-lowercase" data-widget="control-sidebar" href="mailto:memberservices@usawaterski.org">
              <img style="width:18px;" src="/dist/img/icon-mail.svg"> memberservices@usawaterski.org
            </a>
          </li>
        </ul>
          <button class="btn shadow-none  d-flex flex-wrap align-items-center  p-0" type="button"
                  id="userProfileNav" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="header_icon mr-2">
                    <img src="/dist/img/avatarimg.png" alt="profile image"/>                   
                  </span>                
                </button>
              <b-dropdown id="dropdown-1" :text="adminInfoData" right class="m-md-2">
            <a class="nav-link logout" data-widget="control-sidebar" @click="logout" data-slide="true" href="#"
              role="button"><img style="width:18px;" src="/dist/img/ico-logout.svg"> Log Out</a>
          </b-dropdown>
        </div>
      </div>
    </nav>

  </div>
</template>

<script>
import moment from "moment";
import { EventBus } from '@/event-bus';

export default {
  name: 'adminNavigation',
  data() {
    return {
      adminInfoData: '',
      prop_footer: {},
      qs: '',
      urlSegment: '',
      header: false,
      mobHeader: false,
    }
  },
  created() {
    /* sidebar to header callback */
    EventBus.$emit('mobTogglerClose', (evt) => {
      console.log("this.mobHeader", this.mobHeader);
      this.mobHeader = evt;
      EventBus.$on('showmenuMob', this.mobHeader)
    })
    console.log("wh  from navigation",)
    /* Desktop to header callback, submenu open always */
    EventBus.$emit('deskTogglerOpen', (evt) => {
      this.header = true;
      EventBus.$emit('showmenuEvent', true);
      if (this.header) {
        document.body.classList.add("sidebar-full-view");
        document.body.classList.remove("sidebar-half-view");
      } else {
        document.body.classList.remove("sidebar-full-view");
        document.body.classList.add("sidebar-half-view");
      }
    });

  },
  methods: {
    deskToggler() {

      /* header to sidebar callback, desktop toggler click */
      this.header = !this.header;
      EventBus.$emit('showmenuEvent', this.header);

      if (this.header) {
        document.body.classList.add("sidebar-full-view");
        document.body.classList.remove("sidebar-half-view");
      } else {
        document.body.classList.remove("sidebar-full-view");
        document.body.classList.add("sidebar-half-view");
      }
    },
    mobToggler() {
      /* header to sidebar callback, mobile toggler click */
      this.mobHeader = !this.mobHeader;
      EventBus.$emit('showmenuMob', this.mobHeader = true);
      // this.$emit('showmenuMob', this.mobHeader)
    },
    logout() {
          let hostName=window.location.hostname;
          let mainDomain=hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
          document.cookie = "LMSACCESSTOKEN=;domain=."+mainDomain+";expires=3;path=/";
      localStorage.removeItem('admintoken');
      localStorage.removeItem('admin');
      localStorage.removeItem('impersonateId');
      this.$router.push('/login');
    },
    globalSearch(event) {
      event.preventDefault();
      let redirectURL = this.getSiteProps("globalSearch.redirect");
      const url = window.location.href;
      const segments = url.split('/'); // Split the URL into segments
      var valindex = 2;
      if (segments.length == 5) {
        valindex = valindex - 1;
      }
      else if (url.indexOf('?') !== -1) {
        valindex = valindex + 1;
      }
      this.urlSegment = segments[segments.length - valindex];
      if (['club-registrations', 'club', 'team-registrations', 'team'].includes(this.urlSegment)) {
        redirectURL = this.getSiteProps("globalSearch.clubredirect");
      } else if (['campski-registrations', 'campski'].includes(this.urlSegment)) {
        redirectURL = this.getSiteProps("globalSearch.campskiredirect");
      }
      this.$router.push({
        path: redirectURL,
        query: { term: this.qs.trim() },
      });
    }
  },
  mounted() {
    this.deskToggler();
    if (localStorage.getItem('admintoken') == null) {
      this.$router.push('/login');
    }

    this.adminInfoData = JSON.parse(localStorage.getItem('admin'));
    this.prop_footer = this.getSiteProps('memberRegistration.footer');
  }
}
</script>
<style>
.freezeheader {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 999;
  /* Set a high z-index to ensure the header appears on top of other elements */
}</style>