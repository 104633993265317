<template>
   <div>
    <div id="main-wrapper">          
        <navigation></navigation>
        <div  class="">
            <sidebar></sidebar>
        </div>     
            <div id="content-wrapper" class="content-wrapper member-mb">
                <div class="mb-5">
                    <router-view></router-view>
                </div>              
               <loggedindashboardFooter></loggedindashboardFooter>
            </div>      
       </div>
    </div>
</template>

<script>
 import navigation from "../components/Navigation";
import sidebar from "../components/Sidebar";
import loggedindashboardFooter from "../components/LoggedInFooter.vue";


export default {
    name: "memberlayouts",
     components: {navigation, sidebar,loggedindashboardFooter},
}
</script>
