<template>

    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top">
              <!-- <img
                :src="'/'+this.getSiteProps('login.banner')"
                alt="login image"
                class="login-img"
                v-if="this.getSiteProps('login.banner')"
              /> -->
            </div>
            
                <div class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 rightside_section">
                      <router-view></router-view>   
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "clubauthlayout",
    
}
</script>