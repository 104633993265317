<template>
    <svg
                        role="img"
                        width="105"
                        height="460"
                        aria-labelledby="loading-aria"
                        viewBox="0 0 105 460"
                        preserveAspectRatio="none"
                        >
                        <title id="loading-aria">Loading...</title>
                        <rect
                        x="0"
                        y="0"
                        width="100%"
                        height="100%"
                        clip-path="url(#clip-path)"
                        style='fill: url("#fill");'
                        ></rect>
                        <defs>
                        <clipPath id="clip-path">
                        <rect x="30" y="47" rx="9" ry="9" width="50" height="40" /> 
                        <rect x="37" y="96" rx="0" ry="0" width="35" height="7" /> 
                        <rect x="30" y="135" rx="9" ry="9" width="50" height="40" /> 
                        <rect x="37" y="185" rx="0" ry="0" width="35" height="7" /> 
                        <rect x="30" y="225" rx="9" ry="9" width="50" height="40" /> 
                        <rect x="37" y="275" rx="0" ry="0" width="35" height="7" />
                        </clipPath>
                        <linearGradient id="fill">
                        <stop
                        offset="0.599964"
                        stop-color="#d6e8ff"
                        stop-opacity="1"
                        >
                        <animate
                        attributeName="offset"
                        values="-2; -2; 1"
                        keyTimes="0; 0.25; 1"
                        dur="2s"
                        repeatCount="indefinite"
                        ></animate>
                        </stop>
                        <stop
                        offset="1.59996"
                        stop-color="#ffffff"
                        stop-opacity="1"
                        >
                        <animate
                        attributeName="offset"
                        values="-1; -1; 2"
                        keyTimes="0; 0.25; 1"
                        dur="2s"
                        repeatCount="indefinite"
                        ></animate>
                        </stop>
                        <stop
                        offset="2.59996"
                        stop-color="#d6e8ff"
                        stop-opacity="1"
                        >
                        <animate
                        attributeName="offset"
                        values="0; 0; 3"
                        keyTimes="0; 0.25; 1"
                        dur="2s"
                        repeatCount="indefinite"
                        ></animate>
                        </stop>
                        </linearGradient>
                        </defs>
                        </svg>
</template>
<script>
export default { 
    
    name:'sidebarLoader'
}
</script>