import Vue from "vue";
import Axios from 'axios';
import App from "./App.vue";
import router from "./router";
import VueCryptojs from 'vue-cryptojs';

Vue.config.productionTip = false;
Vue.config.silent = true;


import OverlayScrollbars from "os-vue";
import VueBreadcrumbs from "vue-2-breadcrumbs";
import { BootstrapVue, ModalPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueTheMask from "vue-the-mask";
import "vue-date-pick/dist/vueDatePick.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import Select2 from "v-select2-component";
import VAnimateCss from 'v-animate-css';
import VeeValidate from 'vee-validate';
import VueCookies from 'vue-cookies';
import CKEditor from 'ckeditor4-vue';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'



import GlobalVars from './globalVars';

import '../public/icheck-bootstrap/icheck-bootstrap.min.css';
import '../public/dist/css/adminlte.min.css';
import '../public/dist/css/custom.css';
import '../public/dist/css/bootstrap-datepicker3.min.css';
import './components/memberregistration/style.css';
import '../node_modules/overlayscrollbars/css/OverlayScrollbars.min.css';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import JsonExcel from "vue-json-excel";
import './assets/css/newStyle.css';
import './assets/css/responsive.css'

Vue.use(PerfectScrollbar)
Vue.use(BootstrapVue);
Vue.use(ModalPlugin);
Vue.use(VueTheMask);
Vue.use(VueTelInput);
Vue.use(VueBreadcrumbs);
Vue.component("Select2", Select2);
Vue.use(OverlayScrollbars);
Vue.use(VAnimateCss);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueCookies);
Vue.use( CKEditor );
Vue.use(VueCryptojs);
const config = {
  errorBagName: 'formErrors'
};
Vue.use(VeeValidate, config);



// Vue.use(GlobalVars);

new Vue({
  // mixins: [GlobalVars],
  router,
  render: (h) => {
    return h(App)
  },
  beforeMount(){
    const host = window.location.host;
    if(host.toLowerCase().includes("waterski")) Axios.defaults.headers.common['ClientId'] = 1;
    else if(host.toLowerCase().includes("shooting")) Axios.defaults.headers.common['ClientId'] = 2;
    else if(host.toLowerCase().includes("alliance")) Axios.defaults.headers.common['ClientId'] = 3;
    else Axios.defaults.headers.common['ClientId'] = -1;

    console.log("Client ID is setup as " + Axios.defaults.headers.common['ClientId']);
  }
}).$mount("#app");
