<template>
  <div>
    <main>
    <div class="container">
      <div class="row">
        <div class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top">               
        </div>
        <div class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 rightside_section">
         <div
                class="pt-5 border-radius-0 border-0 "
                style="background: transparent;"
              >
                <div class="logo-wrapper text-center mb-2">
                  <img :src="'/'+this.getSiteProps('login.logo')" alt="logo" />
                </div>
                <div class="login-main-wrapper">
                  <div class="login-wrapper">
                    <div class="card-header">
                      <h3>{{ this.getSiteProps('login.admin_login_title') || 'Login' }}</h3>
                    </div>
                    <div class="card card-body mb-0">
                      <form
                        method="post"
                        id="loginForm"
                        @submit.prevent="checkLogin"
                      >
                        <div class="form-group">
                          <label class="form-label">Email</label>
                          <div class="input-group mb-3">
                            <div class="input-group-append">
                              <div class="input-group-text">
                               <i  class="fa fa-user"></i>
                              </div>
                            </div>
                            <input
                              type="email"
                              v-model="email"
                              placeholder="Email"
                              v-bind:class="{ 'is-invalid': isActiveEmail }"
                              name="email"
                              class="form-control"
                            />
                          </div>
                          <div v-bind:class="{ error: isActiveEmail }">
                            {{ errors_email }}
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="form-label">Password</label>
                          <div class="input-group mb-3">
                            <div class="input-group-append">
                              <div class="input-group-text">
                               <i  class="fas fa-key"></i>
                              </div>
                            </div>
                            <input
                              :type="passwordFieldType"
                              v-model="password"
                              class="form-control"
                              v-bind:class="{ 'is-invalid': isActivePassword }"
                              placeholder=" Password"
                              name="password"
                            />
                             <div class="showhide">
                <!-- <i class="fas fa-eye-slash"></i> -->
                <span type="password"  @click="switchVisibility" ><i v-bind:class="{ 'fa-eye-slash': isActive}" class="far fa-eye"></i></span>
                </div>
                          </div>
                          <div v-bind:class="{ error: isActivePassword }">
                            {{ errors_password }}
                          </div>
                        </div>
                        <div class="form-button">
                          <b-button
                            class="btn-primary btn-transparent mr-1"
                            @click="forgotPassword"
                            >I forgot my password</b-button
                          >
                          <b-button type="submit" class="btn-primary btn-red" :disabled="loading">
                            <i class="fa fa-circle-notch fa-spin" v-if="loading"></i>
                            <span v-else>Login</span>
                          </b-button>
                        </div>
                        <!-- <hr>
                        <b-button style="width:100%;background-color: #05213e;" type="submit" class="btn-primary btn-red" disabled>                            
                            <span>Log in with SSO </span>
                          </b-button> -->
                      </form>
                    </div>
                  </div>
                </div>
                <!-- <div class="dashboard-qustion">
                   <p class="footer-quest pb-3" v-html="this.getSiteProps('clubdashboard.footertopcontent')"></p>
                </div> -->
              </div>
                                                             <div class="footer-inner loginpg">
                        <div class="row footer-row">
                            <div class="col-md-7 pl-3">
                                <div class="footeremail col-md-12 mb-1 pl-0" v-if="prop_footer.phone || prop_footer.email">
                                    <div class="">
                                        <div class=" ">
                                            <p class="footer-quest">Questions? Contact Member Services at <a :href="'tel:'+prop_footer.phone"> {{ prop_footer.phone }}</a> or <a :href="'mailto:'+prop_footer.email">{{ prop_footer.email }}</a></p>
                                        </div>
                                    </div>
                                </div>
                              <ul class="footerulli">
                                <li><p class="text-danger">{{ this.getSiteProps('login.copyright') +' '+ new Date().getFullYear() }}</p></li>
                                <li><div v-html="this.getSiteProps('login.faq')"></div></li>
                              </ul>
                            </div>
                            <div class="col-md-5 pr-4">
                                <p class="text-right mr-2">Powered by <a href="https://www.isportz.co/" target="_blank"><img class="ml-1" style="width:80px" v-bind:src="'/dist/img/logo.svg'" alt="footer-img" /></a></p>
                            </div>
                        </div>
                    </div>
        </div>
        
      </div>
    </div>
  </main>
  </div>
</template>
<style scoped>
.login-section-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 68px 0;
    background-color: #f2f2f2;
}
.login-img {
    width: 100%;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
}

</style>
<script>
import axios from 'axios';
import moment from "moment";
export default {
  name: "memberlogin",
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      errors_email: "",
      errors_password: "",
      error_class: "",
      isActiveEmail: false,
      isActive: false,
      passwordFieldType: "password",
      isActivePassword: false,
       prop_footer: {},
    };
  },
  methods: {
    forgotPassword: function() {
      window.open("/member/forgotpassword", "_self");
    },
      switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";      
      this.isActive = !this.isActive;
      },
    register: function() {
      window.open("/memberregistration", "_self");
    },
    checkLogin: function() {
      if (this.email != "" && this.password != "") {
        this.isActiveEmail = false;
        this.isActivePassword = false;
        this.errors_email = "";
        this.errors_password = "";
        this.loading = true;
        axios
          .post(process.env.VUE_APP_URL + "api/login", { email: this.email, password: this.password })
          .then((response) => {
            if (response.data.status == "success") {
              localStorage.setItem("id", response.data.data.id);
              localStorage.setItem("admintoken", response.data.data.token);
              localStorage.setItem("impersonateId", response.data.data.email);
              localStorage.setItem(
                "admin",
                JSON.stringify(response.data.data.admin_name)
              );
              localStorage.setItem(
                "role",
                JSON.stringify(response.data.data.role_id)
              );
              localStorage.setItem("time", moment().unix());
              localStorage.setItem(
                  "pfm_sso_token",
                  encodeURIComponent(this.$CryptoJS.AES.encrypt(response.data.data.email, "isportz").toString())
              );
              this.setEMSCookie(response?.data?.data);

              let d = new Date();
              d.setTime(d.getTime() + 60 * 60 * 1000);
              let expires = "expires=" + d.toUTCString();
              let hostName=window.location.hostname;
              let mainDomain=hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
              let userType = 'user';
              document.cookie = "LMSACCESSTOKEN=" + response.data.data.lmsAccessToken + ";domain=." + mainDomain + ";" + expires + ";path=/;type=" + userType;
              if(this.returnurl !='' && this.returnurl != undefined){
                window.location.href=this.returnurl;
              }else {
                this.$router.push(window.open("/admin/dashboard", "_self"));
              }
            } else {
              this.errors_password =
                "These credentials do not match our records.";
              this.isActivePassword = true;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.loaded = true;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors || {};
            }
            this.loading = false;
          });
      } else {
        if (!this.email) {
          this.errors_email = "The email field is required.";
          this.isActiveEmail = true;
        } else {
          this.errors_email = "";
          this.isActiveEmail = false;
        }
        if (!this.password) {
          this.errors_password = "The password field is required.";
          this.isActivePassword = true;
        } else {
          this.errors_password = "";
          this.isActivePassword = false;
        }
      }
    },
    setEMSCookie(data) {
      let sso_cookie = data?.emsSsoToken;
      let d = new Date();
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = sso_cookie.name+"=" + sso_cookie.token + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
      document.cookie = "PFM_TOKEN=" + encodeURIComponent(this.$CryptoJS.AES.encrypt(data?.email, "isportz").toString()) + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
    }
  },
  beforeMount(){
    if (this.$route.query.logout !== undefined) {
      localStorage.clear();
    } else {
      if (localStorage.admintoken != undefined) this.$router.push("/admin/dashboard");
      this.returnurl = this.$route.query.returnurl;
    }
  },
   updated(){           
      this.prop_footer = this.getSiteProps('memberRegistration.footer');
   }
};
</script>
