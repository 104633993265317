<template>
   <div id="main-wrapper">
      <clubnavigation></clubnavigation>
      <div  class="">
         <clubsidebar></clubsidebar>
      </div>
            <div  id="content-wrapper" class="content-wrapper club-mb">
               <div class="mb-5">
                  <router-view :key="$route.fullPath"></router-view>
               </div>              
               <clubdboardfooter></clubdboardfooter>
            </div>     
    </div>
</template>

<script>
import clubnavigation from "../components/Navigation";
import clubsidebar from "../components/Sidebar";
import clubdboardfooter from "../components/Footer";


export default {
    name: "ClubLayout",
     components: {clubnavigation, clubsidebar,clubdboardfooter},
}
</script>
