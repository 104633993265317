<template>
  <div class="freezeheader">
  <nav class="main-header navbar navbar-expand navbar-white navbar-light p-0">
    <!-- Left navbar links -->
     
     <a href="javascript:void(0)" data-widget="pushmenu" class="nav-toggler deskToggler" :class="header ? 'menu_opened' : 'menu_closed'"  @click="deskToggler">
      <div class="nav-toggle">
        <div class="icon-bar"></div>
        <div class="icon-bar"></div>
        <div class="icon-bar"></div>
      </div>
    </a>
    <a href="javascript:void(0)" class="nav-toggler mobToggler" :class="header ? 'menu_opened' : 'menu_closed'" @click="mobToggler">
      <div class="nav-toggle">
        <div class="icon-bar"></div>
        <div class="icon-bar"></div>
        <div class="icon-bar"></div>
      </div>
    </a>
    <div class="header-main">
    <ul class="navbar-nav">
      <li class="nav-item app-logo mr-4">        
      <a href="#"  v-if="this.getSiteProps('member.logo') == undefined">
        <img
          :src="'/' + this.getSiteProps('login.logo')"
          v-if="'/' + this.getSiteProps('login.logo')"         
          style="opacity: 1;"
        />
        <span class="brand-text font-weight-bolder"></span>
      </a>
      <a href="#"  v-else>
        <img
          :src="'/' + this.getSiteProps('member.logo')"
          v-if="'/' + this.getSiteProps('member.logo')"         
          style="opacity: 1;"
        />
        <span class="brand-text font-weight-bolder"></span>
      </a>    
        <!-- <img  style="margin-left:20px;margin-right:20px;width:80px;" src="../../../../../public/shooting/logo.png" alt="logo" /> -->
    </li>
      <!-- <li class="nav-item ">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li> -->
      <!-- <li class="nav-item d-none d-sm-inline-block">
        <Breadcrumbs/>
      </li> -->

    </ul>

    <!-- SEARCH FORM -->
    <!-- Right navbar links -->



      <!-- <li class="nav-item">
        <div class="user-panel pt-1 pb-1 d-flex">
          <div class="image">
            <img :src="getClubLogoImg()" alt="User Image" class="img-circle">
          </div>
          <div class="info">
            <a href="#" class="d-block text-capitalize">{{ clubInfoData.name }}</a>
          </div>
        </div>
      </li>
      <li class="nav-item" v-if="impersonateId == null">
        <a class="nav-link logout" data-widget="control-sidebar" @click="logout" data-slide="true" href="#"
           role="button">
          <i class="fas fa-sign-out-alt"></i>Log Out
        </a>
      </li>
      <li class="nav-item" v-else>
        <a class="nav-link logout" data-widget="control-sidebar" @click="switchAdmin()" data-slide="true" href="#"
           role="button">
          <i class="fas fa-sign-out-alt"></i> Switch to Admin
        </a>
      </li> -->
    

        <div class="d-flex align-items-center profile-dropdown ml-auto">
          <ul class="navbar-nav  align-items-center ml-auto rightnavbar">
      <li class="nav-item top-barnav w-phone-hide" v-if="subdomain == 'waterski'">
        <a class="nav-link logout" data-widget="control-sidebar" href="tel:863-324-4341">
          <img style="width:18px;" src="/dist/img/ico-phone.svg"> 863-324-4341
        </a>
      </li>
      <li class="nav-item top-barnav  w-email-hide" v-if="subdomain == 'waterski'">
        <a class="nav-link logout text-lowercase" data-widget="control-sidebar" href="mailto:memberservices@usawaterski.org">
          <img style="width:18px;" src="/dist/img/icon-mail.svg"> memberservices@usawaterski.org
        </a>
      </li>
      <li class="nav-item mr-3 mb-faq" v-if="subdomain == 'waterski'">
          <router-link  to="/club/faq"><i class="fa fa-question-circle mt-0" aria-hidden="true"></i></router-link>
      </li>
      <li class="nav-item mr-3 mb-faq" v-else>
          <router-link  to="/help-and-support" target="_blank" class="nav-link"><i class="fa fa-question-circle mt-0" aria-hidden="true"></i> FAQ</router-link>
      </li>
    </ul>
        <button class="btn shadow-none  d-flex flex-wrap align-items-center  p-0" type="button"
                  id="userProfileNav" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="header_icon mr-2">
                    <img src="/dist/img/avatarimg.png" alt="profile image"/>                   
                  </span>                
                </button>
        <b-dropdown id="dropdown-1" :text="clubInfoData.name" right  class="m-md-2">
            <li class="nav-item" v-if="impersonateId == null">
                <a class="nav-link logout" data-widget="control-sidebar" @click="logout" data-slide="true" href="#" role="button">
                <i class="fas fa-sign-out-alt"></i>Log Out
                </a>
            </li>
            <li class="nav-item" v-else>
              <a class="nav-link logout" data-widget="control-sidebar" @click="switchAdmin()" data-slide="true" href="#" role="button">
                <i class="fas fa-sign-out-alt"></i>Switch to Admin
              </a>
            </li>           
        </b-dropdown>
    </div>
    </div>
  </nav>
</div>
</template>

<script>
import moment from "moment";
import { EventBus } from '@/event-bus';
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import axios from "axios";

export default {
  name: 'clubNavigation',
  data() {
    return {
      clubInfoData: [],
      impersonateId: null,
      header: false,
      mobHeader: false,
    }
  },
  created() {     
    /* sidebar to header callback */
    EventBus.$emit('mobTogglerClose', (evt) => {
      console.log("this.mobHeader", this.mobHeader);
      this.mobHeader = evt;
      EventBus.$on('showclubmenuMob', this.mobHeader)
    })
    console.log("wh  from navigation",)
    /* Desktop to header callback, submenu open always */
    EventBus.$emit('deskTogglerOpen', (evt) => {
      this.header = true;
      EventBus.$emit('showClubmenuevent', true);
      if (this.header) {
        document.body.classList.add("sidebar-full-view");
        document.body.classList.remove("sidebar-half-view");
      } else {
        document.body.classList.remove("sidebar-full-view");
        document.body.classList.add("sidebar-half-view");
      }
    });
   
  },
  methods: {
    deskToggler() {      
      /* header to sidebar callback, desktop toggler click */
      this.header = !this.header;
      EventBus.$emit('showClubmenuevent', this.header);   
      if (this.header) {
        document.body.classList.add("sidebar-full-view");
        document.body.classList.remove("sidebar-half-view");
      } else {
        document.body.classList.remove("sidebar-full-view");
        document.body.classList.add("sidebar-half-view");
      }
    },
    mobToggler() {
      /* header to sidebar callback, mobile toggler click */
      this.mobHeader = !this.mobHeader;
      EventBus.$emit('showclubmenuMob', this.mobHeader= true);
      // this.$emit('showclubmenuMob', this.mobHeader)
    },
    logout() {
      localStorage.removeItem('clubToken');
      localStorage.removeItem('team');
      this.$router.push('/club/login');
    },
    getClubLogoImg() {
      return (this.clubInfoData.logo != null ? process.env.VUE_APP_URL + this.clubInfoData.logo : '/dist/img/avatarimg.png') + '?v=' + moment().format('x');
    },
    switchAdmin() {
      axios
          .post(process.env.VUE_APP_URL + "api/impersonate/admin", {
            email: this.impersonateId
          })
          .then((response) => {
            if (response.data.status == "success") {
              localStorage.removeItem('clubToken');
              localStorage.removeItem('team');
              localStorage.setItem("id", response.data.data.id);
              localStorage.setItem("admintoken", response.data.data.token);
              localStorage.setItem("impersonateId", response.data.data.email);
              localStorage.setItem(
                  "admin",
                  JSON.stringify(response.data.data.admin_name)
              );
              localStorage.setItem(
                  "role",
                  JSON.stringify(response.data.data.role_id)
              );
              localStorage.setItem("time", moment().unix());
              if(this.subdomain=='alliancefastpitch'){
              this.$router.push( window.open("/admin/team-registrations/"+this.clubInfoData.id, "_self"));
              }else{
              this.$router.push( window.open("/admin/club-registrations/"+this.clubInfoData.id, "_self"));
              }
             
            }
          })
          .catch((error) => {

          });
    },
  },
  mounted() {
    this.deskToggler();
    this.clubInfoData = JSON.parse(localStorage.getItem('team'));
    this.impersonateId = localStorage.getItem('impersonateId')
  }
}
</script>
