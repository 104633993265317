<template>
    <div id="main-wrapper">       
        <Navigation></Navigation>
      <div  class="">
          <Sidebar></Sidebar>
      </div>
    <div id="content-wrapper" class="content-wrapper">
        <div class="mb-5">
          <router-view />
        </div>
        <adminfooter></adminfooter>
    </div>
   
 </div>


  
  </template>

<script>
import adminfooter from "../components/Footer.vue";
import Navigation from '../components/Navigation.vue'
import Sidebar from '../components/Sidebar.vue';


// import Navigation from "../../Member/Dashboard/components/Navigation.vue";
// NavigationVue

export default {
  name: 'HeaderLayout',
  components:{
    Navigation,
    adminfooter,
    Sidebar
  }
}

</script>
